﻿define(['jquery', 'validate'], function ($, validate) {
	var module = {};

	module.validate = function validateMethod(attributes, constraints) {
		return validate(attributes, constraints);
	};

	module.validateAndWatch = function validateAndWatchFormMethod($form, constraints) {
		var formData, invalid;

		formData = module.serializeObject($form);
		invalid = module.validate(formData, constraints);

		module.clearErrors($form);

		if (!invalid) {
			return invalid;
		}

		$form.off('change.reValidationUtilities').on('keyup.reValidationUtilities change.reValidationUtilities', function onFormChange() {
			var formData, invalid;

			formData = module.serializeObject($form);
			invalid = module.validate(formData, constraints);

			module.clearErrors($form);

			if (invalid) {
				module.showErrors($form, invalid, false);
			}
		});

		module.showErrors($form, invalid, true);

		return invalid;
		
	};

	module.clearErrors = function clearErrorsMethod($form) {
		$form.find(":input, .re-input-wrapper").removeClass('error');
		$form.find('.error-message').remove();
	};

	module.showErrors = function showErrorsMethod($form, errors, jump) {
		jump = (typeof jump !== 'undefined') ? jump : true;

		module.clearErrors($form);

		if (errors) {
			//for each validation error add a red box
			$.each(errors, function (key, value) {
				var $target, $wrapper, message;

				$target = $form.find(['[name="', key, '"]'].join(''));
				$wrapper = $target.parent('.re-input-wrapper');
				message = ['<div class="error error-message">', value[0], '</div>'].join('');

				if ($target.length) {
					$target.addClass('error');

					//if checkbox
					if ($target.is(':checkbox[id]')) {
						$target = $target.add($form.find('[for="' + $target.attr('id') + '"]')).last();
					}

					$target.after(message);
						
					if ($wrapper.length) {
						$wrapper.addClass('error');
					}

				} else {
					$form.prepend(message);
				}
			});

			if (jump === true) {
				$('html,body').animate({
					scrollTop: $form.find('.error-message').parent().offset().top - 50
				}, 'fast');
			}

		}
	};

	module.serializeObject = function serializeObjectMethod($form) {
		var obj, data, i, l, name;

		obj = {};
		data = $form.serializeArray();
		for (i = 0, l = data.length; i < l; i++) {
			name = data[i].name;

			if (obj.hasOwnProperty(name)) {
				if (data[i].value) {
					obj[name] = [obj[name], data[i].value].join()
				}
			} else {
				obj[name] = data[i].value;
			}
		}

		return obj;
	};

	return module;
});
