import _pathOr from 'ramda/src/pathOr';
import loggly from '../API/Loggly';
import { LOGGLY_LEVEL_ERROR } from '../Constants';

const resx = (library, resource) => {
	const r = _pathOr('', ['RESAAS', 'Localization', library, resource], window);
	const l = window && window.RESAAS && window.RESAAS.Localization ? `Library is ${typeof window.RESAAS.Localization[library]}` : 'Localization is not defined';

	if (!r) {
		loggly.add(LOGGLY_LEVEL_ERROR, `Resource is missing - React: ${library}.${resource}. ${l}`);
	}

	return r;
};

export default resx;