﻿define(function () {
	'use strict';

	var module;

	module = {};

	module.createServiceObject = function (request, moduleProperties, requestOptions) {
		var serviceObject;

		serviceObject = requestOptions || {};
		serviceObject.guid = moduleProperties.guid;
		serviceObject.request = request;

		return serviceObject;
	};

	module.isOwnEvent = function (data, moduleProperties) {
		if (data && data.guid && data.guid === moduleProperties.guid) {
			return true;
		}
		return false;
	};

	return module;
})