export const LOGGLY_LEVEL_ERROR = 'ERROR';
export const LOGGLY_LEVEL_WARN = 'WARN';
export const LOGGLY_LEVEL_INFO = 'INFO';

export const RESX_ONBOARDWIZARD = 'OnboardWizard';
export const RESX_BROKEROS = 'BrokerOS';
export const RESX_DASHBOARD = 'Dashboard';
export const RESX_GLOBAL = 'Global';
export const RESX_REGIONS = 'Regions';
export const RESX_LISTINGS = 'Listings';
export const RESX_REBLASTS = 'Reblasts';
export const RESX_REFERRALS = 'Referrals';
export const RESX_NOT_FOR_TRANSLATION = 'NotForTranslation';
export const RESX_USERSEARCH = 'UserSearch';
export const RESX_REFERRAL_CREATION_WIZARD = 'ReferralCreationWizard';
export const RESX_REFERRAL_DETAIL = 'ReferralDetail';
export const RESX_REFERRAL_DASHBOARD = 'ReferralsDashboard';
export const RESX_RESAAS_PAY_VERIFIED = 'ResaasPayVerified';
export const RESX_PRICING_PLAN = 'PricingPlan';
export const RESX_PARTNERSHIPS = 'Partnerships';
export const RESX_NAVIGATION = 'Navigation';

export const MIXPANEL_REGISTER = 'New Account';
export const MIXPANEL_CONTENT = 'Add Content';
export const MIXPANEL_DELETE = 'Delete';
export const MIXPANEL_LAND = 'Landed';
export const MIXPANEL_INTENT = 'Intent';
export const MIXPANEL_SEARCH = 'Search';
export const MIXPANEL_RELATIONSHIP = 'Relationship';
export const MIXPANEL_AUTHENTICATION = 'Authentication';
export const MIXPANEL_RESULT = 'Result';
export const MIXPANEL_PAYMENT = 'Payment';


export const EXPERIENCE_COMMERCIAL = 'commercial';

export const REGEX_EMAIL = '([^<>\\(\\)\\[\\]\\.,;:\\s@"]+(\\.[^<>\\(\\)\\[\\]\\.,;:\\s@"]+)*)@([a-zA-Z\\-0-9]+\\.)+([a-zA-Z]{2,})';

