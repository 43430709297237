﻿define(['jquery', 'handlebarsLib', 'pubsub', 'generalUtilities'],
    function ($, Handlebars, pubsub, generalUtilities) {
        'use strict';

        /* HELPERS */
        /* ----------------------------------- */

        Handlebars.registerHelper('LocalizeResource', function (k) {
            var errorMessage, resx, key, keyArray, l;

            if (k.indexOf('.') !== -1) {
                keyArray = k.split('.');
                resx = keyArray[0];
                key = keyArray[1];
            } else {
                resx = 'Global';
                key = k;
            }

            if (window.RESAAS && window.RESAAS.Localization && window.RESAAS.Localization[resx] && window.RESAAS.Localization[resx][key]) {
                return window.RESAAS.Localization[resx][key];
            } else {

                if (window && window.RESAAS && window.RESAAS.Localization) {
                    l = 'Library is ' + typeof window.RESAAS.Localization[resx]
                } else {
                    l = 'Localization is not defined';
                }

                errorMessage = ['Resource is missing - Handlebars:', k, l].join(' ');
                pubsub.publish('loggly.warning', { 'message': errorMessage });
                return '';
            }
        });

        Handlebars.registerHelper('ShortenString', function (message, count, ellipsis) {
            var ellipsisHTML, lastWhiteSpace;

            if (typeof message === 'undefined' || !message) {
                return ''
            }

            message = message.replace(/<[^>]*>/igm, "");
            message = message.replace(/\t/gm, "");
            lastWhiteSpace = message.lastIndexOf(" ", count);

            if (count < 0) {
                return message;
            }

            if (message.length <= count) {
                return message;
            }

            message = message.substring(0, count);

            ellipsis = (ellipsis === 'true') ? true : false;
            ellipsisHTML = "...";

            if (lastWhiteSpace === -1) {
                return message.substring(0, count - ellipsisHTML.length) + ellipsisHTML;
            }

            if (ellipsis) {
                return $.trim(message.substring(0, (lastWhiteSpace > 0) ? lastWhiteSpace : (count - ellipsisHTML.Length))) + ellipsisHTML;
            }
            else {
                return $.trim(message.substring(0, (lastWhiteSpace > 0) ? lastWhiteSpace : count));
            }
        });

        Handlebars.registerHelper('IfEquals', function (lvalue, rvalue, options) {
            if (arguments.length === 3) {
                if (lvalue != rvalue) {
                    return options.inverse(this);
                } else {
                    return options.fn(this);
                }
            }
        });

        Handlebars.registerHelper('RenderSelectOptions', function (options, selected, name_selected, optionName) {
            var i, l, html, errorMessage;
            if (Object.prototype.toString.call(options) !== '[object Array]') { return ''; }
            html = [];

            for (i = 0, l = options.length; i < l; i++) {
                if (options[i].id == 'optgroup') {
                    var optgrouptxt = '<optgroup label="' + options[i].name + '">';
                    html.push(optgrouptxt);
                } else if (options[i].id == 'optgroupend') {
                    html.push('</optgroup>');
                } else {
                    html.push('<option value="');
                    html.push(options[i].id);
                    html.push('"');
                    if (options[i].id == selected || ((typeof options[i].id === "string") && (options[i].id.toLowerCase() === selected))) {
                        html.push(' selected');
                    } else if (options[i].id === null && options[i].name === name_selected) {
                        html.push(' selected');
                    }
                    html.push('>');
                    html.push(options[i].name);
                    html.push('</option>');
                }
                if (optionName && !options[i].name) {
                    errorMessage = ['The Handlebars helper RenderSelectOptions could not find a string using the key ', options[i].id, 'from option name of ', optionName].join(' ');
                    pubsub.publish('loggly.error', { 'message': errorMessage });
                }
            }

            return new Handlebars.SafeString(html.join(''));
        });

        Handlebars.registerHelper('RenderLocalizedSelectOptions', function (options, selected, sort, strTopLevelItems, strBotLevelItems) {
            var i, j, l, k, html, pathArray, resx, key, name, errorMessage, removedItems, topLevelItems, botLevelItems;

            if (Object.prototype.toString.call(options) !== '[object Array]') { return ''; }

            sort = ('boolean' === typeof sort && sort) || false;

            html = [];

            for (i = 0, l = options.length; i < l; i++) {
                pathArray = options[i].resourceName.split('.');
                resx = pathArray[0];
                key = pathArray[1];

                if (window.RESAAS.Localization[resx] && window.RESAAS.Localization[resx][key]) {
                    options[i].name = window.RESAAS.Localization[resx][key];
                } else {
                    errorMessage = ['The Handlebars helper RenderLocalizedSelectOptions could not find a string using the key', options[i].resourceName].join(' ');
                    pubsub.publish('loggly.warning', { 'message': errorMessage });
                    options[i].name = options[i].resourceName;
                }
            }

            if (sort) {
                options.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });
            }

            // parse top and bot level items
            if (sort && strTopLevelItems && typeof (strTopLevelItems) === "string") {
                topLevelItems = JSON.parse(strTopLevelItems);
            }
            if (sort && strBotLevelItems && typeof (strTopLevelItems) === "string") {
                botLevelItems = JSON.parse(strBotLevelItems);
            }

            // remove items and add them to beginning of the options list
            if (sort && topLevelItems && topLevelItems.length) {
                removedItems = [];
                for (i = 0, l = options.length; i < l; i++) {
                    for (j = 0, k = topLevelItems.length; j < k; j++) {
                        if (options[i] && options[i].id === topLevelItems[j]) {
                            removedItems[j] = options[i];
                            options.splice(i, 1);
                        }
                    }
                }

                if (removedItems.length) {
                    for (i = 0, l = removedItems.length; i < l; i++) {
                        options.unshift(removedItems[i]);
                    }
                }
            }

            // remove items and add them to end of the options list
            if (sort && botLevelItems && botLevelItems.length) {
                removedItems = [];
                for (i = 0, l = options.length; i < l; i++) {
                    for (j = 0, k = botLevelItems.length; j < k; j++) {
                        if (options[i] && options[i].id === botLevelItems[j]) {
                            removedItems[j] = options[i];
                            options.splice(i, 1);
                        }
                    }
                }

                if (removedItems.length) {
                    for (i = 0, l = removedItems.length; i < l; i++) {
                        options.push(removedItems[i]);
                    }
                }
            }

            for (i = 0, l = options.length; i < l; i++) {
                html.push('<option value="');
                html.push(options[i].id);
                html.push('"');
                if (options[i].id == selected) {
                    html.push(' selected');
                }
                html.push('>');
                html.push(options[i].name);
                html.push('</option>');
            }

            return new Handlebars.SafeString(html.join(''));
        });

        //pulled from:
        //https://funkjedi.com/technology/412-every-nth-item-in-handlebars/
        Handlebars.registerHelper('EachGroupOf', function (every, context, options) {
            var out = "", subcontext = [], i;
            if (context && context.length > 0) {
                for (i = 0; i < context.length; i++) {
                    if (i > 0 && i % every === 0) {
                        out += options.fn(subcontext);
                        subcontext = [];
                    }
                    subcontext.push(context[i]);
                }
                out += options.fn(subcontext);
            }
            return out;
        });

        Handlebars.registerHelper('distanceOptions', function () {
            var distances = [10, 20, 30, 50, 75, 100];

            var options = '';
            var i;

            if (generalUtilities.prop(window, 'RESAAS.User2.CountryId') === 224) {
                for (i = 0; i < distances.length; i++) {
                    var value = Math.ceil(generalUtilities.unitDistanceConvertorTo('km', distances[i]));
                    var selected = (distances[i] === 20) ? ' selected' : '';
                    options += '<option value="' + value + '"' + selected + '>' + distances[i] + ' miles</option>';
                }
            } else {
                for (i = 0; i < distances.length; i++) {
                    var selected = (distances[i] === 20) ? ' selected' : '';
                    options += '<option value="' + distances[i] + '"' + selected + '>' + distances[i] + ' kms</option>';
                }
            }

            return new Handlebars.SafeString(options);
        });


		return Handlebars;

    }

);
