/*** IMPORTS FROM imports-loader ***/
var jQuery = require("jquery");

const MIXPANEL_PROXY_DOMAIN = 'https://mp.resa.as';
const MIXPANEL_CUSTOM_LIB_URL = MIXPANEL_PROXY_DOMAIN + '/lib.min.js';

// Snippet
(function (e, a) {
    var mpSrc = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL ? MIXPANEL_CUSTOM_LIB_URL : "file:" === e.location.protocol && "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//) ? "https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js" : "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";
    if (document.querySelector('script[src="' + mpSrc + '"]')) {
        return;
    }
    if (!a.__SV) {
        var b = window;
        try {
            var c, l, i, j = b.location, g = j.hash;
            c = function (a, b) {
                return (l = a.match(RegExp(b + "=([^&]*)"))) ? l[1] : null
            };
            g && c(g, "state") && (i = JSON.parse(decodeURIComponent(c(g, "state"))),
                "mpeditor" === i.action && (b.sessionStorage.setItem("_mpcehash", g),
                    history.replaceState(i.desiredHash || "", e.title, j.pathname + j.search)))
        } catch (m) { }
        var k, h;
        window.mixpanel = a;
        a._i = [];
        a.init = function (b, c, f) {
            function e(b, a) {
                var c = a.split(".");
                2 == c.length && (b = b[c[0]], a = c[1]);
                b[a] = function () {
                    b.push([a].concat(Array.prototype.slice.call(arguments,
                        0)))
                }
            }
            var d = a;
            "undefined" !== typeof f ? d = a[f] = [] : f = "mixpanel";
            d.people = d.people || [];
            d.toString = function (b) { var a = "mixpanel"; "mixpanel" !== f && (a += "." + f); b || (a += " (stub)"); return a };
            d.people.toString = function () {
                return d.toString(1) + ".people (stub)"
            };
            k = "disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
            for (h = 0; h < k.length; h++)e(d, k[h]); a._i.push([b, c, f])
        };
        a.__SV = 1.2;
        b = e.createElement("script");
        b.type = "text/javascript";
        b.async = !0;
        b.src = mpSrc;
        c = e.getElementsByTagName("script")[0];
        c.parentNode.insertBefore(b, c);
    }
})(document, window.mixpanel || []);

navigator.browserVersion = (function () {
    var N = navigator.appName, ua = navigator.userAgent, tem;
    var M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
    M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
    return M;
})()[1].split('.')[0]; // parse to int may result in NaN in some browsers


if (!window.RESAAS) {
    window.RESAAS = {}
}

if (!RESAAS.ContentType) {
    RESAAS.ContentType = {
        1: 'Reblast',
        2: 'Listing',
        3: 'Question',
        4: 'Answer',
        5: 'ProfilePhoto',
        6: 'ListingPhoto',
        7: 'Firm',
        8: 'Realtor'
    };
    //['','REBLAST','Listing','Question','Answer','ProfilePhoto','ListingPhoto','Firm','Realtor']
}

RESAAS.MixPanel = RESAAS.MixPanel || (function () {
    var $ = jQuery;

    // The reason to keep this variable is so we keep our events consistent if this goes to mobile
    var EVENTS = {
        REGISTER: 'New Account',
        CONTENT: 'Add Content',
        DELETE: 'Delete',
        LAND: 'Landed',
        INTENT: 'Intent',
        SEARCH: 'Search',
        RELATIONSHIP: 'Relationship',
        AUTHENTICATION: 'Authentication',
        RESULT: 'Result',
        PAYMENT: 'Payment'
    };

    var C = function () {
        var args = Array.prototype.slice.call(arguments);
        var console = window.console;
        args[0] = "RESAAS MIXPANEL: " + args[0];
        //TODO: Let's key off debug var to enable or disable the console logging
        //if (typeof console == 'object' && console.log && console.log.apply) {
        //	console.log.apply(console, args);
        //}
    };

    var promiseTimeOut = 500;
    var pageTracked = false;

    function MixPanel(options) {
        var self = this;

        this.clearCookieOnLoad = false;
        this.isProduction = location.host === 'www.resaas.com';
        this.isStaging = location.host === 'staging.resaas.com';
        this.isTest = location.host === 'test.resaas.com';
        this.options = {
            loaded: (function () {
                return self.onLoad()
            })
        };

        $.extend(this.options, options);

        // we can push events here to test with Casper (?)
        this.eventsTracked = [];
        this.personTracked = [];

        // unless on prod || staging || test || developing - don't track events
        this.enabled = this.isProduction || this.isStaging || this.isTest || this.options.test;

        if (this.isProduction) {
            mixpanel.init("18f6471fe60ce4c3752d162786911e9e", { api_host: MIXPANEL_PROXY_DOMAIN });
            C('in production');
        } else if (this.isStaging) {
            mixpanel.init("8a6e0a60fe3cb6c305bb9f255efc464c", { api_host: MIXPANEL_PROXY_DOMAIN });
            C('in staging');
        } else if (this.isTest) {
            mixpanel.init("6479c7f3f98c93fd47c4547d1bf07d72", { api_host: MIXPANEL_PROXY_DOMAIN });
            C('in test');
        } else {
            // whoever develop locally should make themselves an account to mixpanel and replace this token below
             mixpanel.init("0fe4630e766265843416c853478cd500", { api_host: MIXPANEL_PROXY_DOMAIN }); // RESAAS Dev
            // mixpanel.init("223c748767deefff9be7d564cece88da", { api_host: MIXPANEL_PROXY_DOMAIN }); // RESAAS - John A
            //  mixpanel.init("e2a9edadbb7f05799ae91f3744eb51a4", { api_host: MIXPANEL_PROXY_DOMAIN }); // RESAAS - May
            C('in dev');
        }

        mixpanel.set_config(this.options);

        if (!this.enabled) {
            mixpanel.disable();
            C('is disabled!');
        } else {
            C('is enabled!');
        }
    }

    MixPanel.prototype.onLoad = function () {
        if (this.clearCookieOnLoad) {
            mixpanel.cookie.clear();
        }
        $('html').on('click.mixpanel change.mixpanel', '[data-mixpanel-name]', $.proxy(this.trackEventFromDataAttr, this));
    };

    MixPanel.prototype.identify = function () {
        var userId, props, setOnceProps, date;
        // to be set whenever the user is on own profile, so we know who he is
        userId = (RESAAS && RESAAS.User2 && RESAAS.User2.UserId) ? RESAAS.User2.UserId : null;
        setOnceProps = {};

        props = {
            'Client': 'resaas.com',
            'Browser Version': navigator.browserVersion
        };

        if (userId) {
            //identify user by linking userid to unique id (alias)
            mixpanel.identify(userId);

            // is the user a pro
            props['Professional'] = RESAAS.User2.IsProfessional && !RESAAS.User2.IsPendingProfessional;

            // does the user have a firm
            props['Has firm'] = (RESAAS.User2 && RESAAS.User2.FirmId) ? true : false;
            
            // if they have one, set the users firm name
            if (props['Has firm'] && RESAAS.User2 && RESAAS.User2.FirmName) {
                props['Firm name'] = RESAAS.User2.FirmName;
            }

            //backfill important profile properties
            //Client Type
            props["Client Type"] = (RESAAS.User2.IsPendingProfessional) ? 'pending professional' : (RESAAS.User2.IsProfessional) ? 'professional' : 'regular';

            //Users Groups
            props['Groups'] = (RESAAS.User2.Groups);

            //Office Name
            props['Firm Office Name'] = RESAAS.User2.FirmOfficeName;

            //First Name
            if (RESAAS.User2.FirstName) { props.$first_name = RESAAS.User2.FirstName; }

            //Last Name
            if (RESAAS.User2.LastName) { props.$last_name = RESAAS.User2.LastName; }

            //Email Address
            if (RESAAS.User2.Email) { props.$email = RESAAS.User2.Email; }

            //Profile City
            if (RESAAS.User2.City) { props.ProfileCity = RESAAS.User2.City; }

            //Mobile Number
            if (RESAAS.User2.MobileNumber) { props.$phone = RESAAS.User2.MobileNumber; }

            //DomainUrl
            if (RESAAS.User2.DomainUrl) { props['Personal Link'] = 'https://' + window.location.host + RESAAS.User2.DomainUrl; }

            //Broker OS
            props['Broker OS'] = (RESAAS.User2.BrokerOSGroupName) ? true : false;

            //Broker OS Group
            props['Broker OS Group'] = RESAAS.User2.BrokerOSGroupName;

            //Broker OS Owner
            props['Broker OS Owner'] = RESAAS.User2.IsBrokerOwner;

            //Email Status (set once)
            if (RESAAS.User2.CountryId !== 38) {
                setOnceProps['Email Status'] = 'Good';
                setOnceProps['Express Consent'] = 'False';
            }

            // Keller Williams GPS plan
            props['KWGPS plan'] = RESAAS.User2.KWGPSPlan;

            //Set People Properties
            this.setPeople(props);

            //Created Date
            date = new Date();
            setOnceProps.$created = date.toUTCString();
            setOnceProps.$iterable_user_id = userId;

            //SetOnce People Properties
            mixpanel.people.set_once(setOnceProps);
        }

        //register super properties (attached to other events)
        mixpanel.register(props);
    };

    MixPanel.prototype.publishLocalStorage = function (publish) {
        if (!window.Modernizr || !window.Modernizr.localstorage) {
            return false;
        }

        var key = localStorage.getItem('RESAAS.MixPanelValue');
        if (key) {
            switch (key) {
                case 'listing':
                    publish('intent.createbutton.listing.click', {
                        type: 'Create Listing',
                        from: 'My Listing Page'
                    });
                    break;
                case 'referral':
                    publish('intent.createbutton.referral.click', {
                        type: 'Create Lead'
                    });
                    break;
                case 'CreateLeadFromCreatePanel':
                    publish('intent.createbutton.referral.click', {
                        type: 'Create Lead',
                        from: 'Toolbar Create Dropdown'
                    });
                    break;
                case 'referralModule':
                    publish('intent.referralModule.referral.click', {
                        type: 'Create Button Referral',
                        from: 'Interactive Referral Feed'
                    });
                    break;
                case 'reblast':
                    $('.component-profile-add-reblasts textarea').addClass('bg-highlight');
                    publish('intent.createbutton.reblast.click', {
                        type: 'Create Button Reblast',
                        from: 'Toolbar Create Dropdown '
                    });
                    break;
                case 'toolbarSearchListings':
                    publish('mixpanel.Intent', {
                        type: 'Search Listings',
                        from: 'Toolbar Listings Dropdown'
                    });
                    break;
                case 'toolbarMyListings':
                    publish('mixpanel.Intent', {
                        type: 'My Listings',
                        from: 'Toolbar Listings Dropdown'
                    });
                    break;
                case 'CreateListingFromListingPanel':
                    publish('mixpanel.Intent', {
                        type: 'Create Listing',
                        from: 'Toolbar Listings Dropdown'
                    });
                    break;
                case 'CreateListingFromCreatePanel':
                    publish('mixpanel.Intent', {
                        type: 'Create Listing',
                        from: 'Toolbar Create Dropdown'
                    });
                    break;
                case 'toolbarDiscoverLeads':
                    publish('mixpanel.Intent', {
                        type: 'Discover Leads',
                        from: 'Toolbar Leads Dropdown'
                    });
                    break;
                case 'toolbarOutgoingLeads':
                    publish('mixpanel.Intent', {
                        type: 'Outgoing Leads',
                        from: 'Toolbar Leads Dropdown'
                    });
                    break;
                case 'createLeadFromLeadPanel':
                    publish('mixpanel.Intent', {
                        type: 'Create Lead',
                        from: 'Toolbar Leads Dropdown'
                    });
                    break;
                case 'deleteListing':
                    publish('delete.listing.data.successful', {
                        'from': 'listing details page'
                    });
                    break;
                case 'deleteListingCommercial':
                    publish('delete.listing.data.successful', {
                        from: 'listing details page',
                        type: 'Commercial'
                    });
                    break;
                case 'mlsRegister':
                    this.trackMLSLanding({
                        "type": "register"
                    });
                    break;
                case 'mlsSignIn':
                    this.trackMLSLanding({
                        "type": "signin"
                    });
                    break;
                case 'landOnProfileFromLoginPage':
                    publish('mixpanel.Authentication', { type: 'sign in' });
                    break;
                case 'declineDirectReferral':
                    publish('decline.referral', {
                        type: 'referral decline'
                    });
                    break;
                case 'sendMessageFromBrokerOSOtherAgents':
                    publish('intent.sendMessage.click', {
                        from: 'Broker OS',
                        context: 'other agents'
                    });
                    break;
                case 'globalEliteTakenPageGroupButton':
                    publish('mixpanel.Intent', {
                        type: 'Discussion Group Button',
                        from: 'Already Taken Page',
                        context: 'Global Elite'
                    });
                    break;
                case 'globalElitePassPageGroupButton':
                    publish('mixpanel.Intent', {
                        type: 'Discussion Group Button',
                        from: 'Pass Page',
                        context: 'Global Elite'
                    });
                    break;
                case 'globalEliteFailPageMaterialButton':
                    publish('mixpanel.Intent', {
                        type: 'Certification Material Button',
                        from: 'Fail Page',
                        context: 'Global Elite'
                    });
                    break;
                case 'brokeros-client-history':
                    publish('mixpanel.Intent', {
                        type: 'see all',
                        from: 'client history Broker OS'
                    });
                    break;
                case 'regularuser.listingsButton.click':
                    publish('mixpanel.Intent', {
                        element: 'listingsButton'
                    });
                    break;
                case 'trackMyReferrals':
                    publish('mixpanel.Intent', {
                        type: "Outgoing Leads",
                        from: "Leads page"
                    });
                    break;
                case 'trackCreateReferral':
                    publish('mixpanel.Intent', {
                        type: 'Create Lead',
                        from: 'Leads page'
                    });
                    break;
                case 'trackRespondToReferral':
                    publish('mixpanel.Intent', {
                        type: 'Respond to Lead',
                        from : 'Leads page'
                    });
                    break;

                case 'toolbarAddLocationNotificationLink':
                    publish('mixpanel.Intent', {
                        type: 'add location',
                        from: 'Toolbar'
                    });
                    break;

                case 'trackPublicGroupReferralResponse':
                    var path = window.location.pathname.split('/referral/');
                    publish('mixpanel.Intent', {
                        type : "Update Response Button",
                        from: 'Buyer Need Detail',
                        context: 'public',
                        source: path[1]
                    });
                    break;
                case 'trackFirmOrGroupReferralResponse':
                        var path = window.location.pathname.split('/referral/');
                    publish('mixpanel.Intent', {
                        type : "Update Response Button",
                        from: 'Buyer Need Detail',
                        context: 'firm or group',
                        source: path[1]
                    });
                default: break;
            }
            localStorage.removeItem('RESAAS.MixPanelValue');
        }
    };

    MixPanel.prototype.trackMLSLanding = function (params) {
        var targeturl, date, utcDate, origin, peopleProps, landingType;
        peopleProps = {};
        targeturl = sessionStorage.getItem('RESAAS.MixPanelTargetUrl') || '/';
        sessionStorage.removeItem('RESAAS.MixPanelTargetUrl');

        if (RESAAS && RESAAS.User2 && RESAAS.User2.Origin && RESAAS.User2.Origin !== 'Undefined') {
            origin = RESAAS.User2.Origin;
            peopleProps[origin] = "True";
            landingType = origin + '.Landing';
        }
        if (params.type === "register") {
            date = new Date();
            utcDate = date.toUTCString();
            peopleProps["$created"] = utcDate;
        }
        if (targeturl) {
            function redirectPage() {
                window.location.assign(targeturl);
            }
            setTimeout(redirectPage, 5000);
        }
        this.setPeople(peopleProps);
        this.trackEvent(EVENTS.LAND, {
            page: window.location.pathname,
            type: landingType,
        }, targeturl);
    };

    // This is a callback for after tracking events, we publish a message for wahtever might be waiting
    MixPanel.prototype.afterTrackEvent = function (callback, success, reference) {
        C('just tracked an event!', reference, !!success);
        if (this.publish) this.publish('mixpanel.event.track.done', reference);
        if (callback && typeof callback === 'string') {

            clearTimeout(timerRef);

            window.location.assign(callback);

            var timerRef = setTimeout(function () {
                RESAAS.LogglyController ? RESAAS.LogglyController.warn('Mixpanel did not return before redirect') : null;
                window.location.assign(callback);
            }, 1000);

        }
    };

    MixPanel.prototype.Register = function (data, callback) {
        this.trackEvent(EVENTS.REGISTER, data, callback);
    };


    MixPanel.prototype.setOrigin = function (details) {
        if (RESAAS && RESAAS.User2 && RESAAS.User2.Origin && RESAAS.User2.Origin !== 'Undefined') {
            details.origin = RESAAS.User2.Origin;
        }
    };

    MixPanel.prototype.setAlias = function (id, callback) {
        var self = this;

        mixpanel.track('$create_alias', { 'alias': id }, function (success) {
            if (!success) {
                self.publish('loggly.error', { message: 'alias failed for id: ' + id });
            }
            callback();
        });
        this.eventsTracked.push(['$create_alias', id]);
        C('tracking call: $create_alias');
    };

    MixPanel.prototype.trackEventPromise = function (name, data) {
        var d, obj;

        d = Q.defer();
        obj = {};

        mixpanel.track(name, data, function (success, reference) {	
            obj.success = success;
            obj.reference = reference;
            d.resolve(obj);
        });

        return d.promise.timeout(promiseTimeOut, 'mixpanel request timed out');
    };

    MixPanel.prototype.trackEvent = function (name, details, callback) {
        var self, link, options, guidFromPathname;

        self = this;
        options = {};
        guidFromPathname = window.location.pathname.match(
            /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/g
        );

        // set a new property called "contentId" with GUID from URL as a value to track referrals and reblasts
        if (guidFromPathname) {
            details.contentId = guidFromPathname[0];
        }

        //cache options and sanitize payload
        options = (details.options) ? details.options : null;
        delete details.options;

        // add pageType to all requests
        if (!details.pageType) {
            details.pageType = window.pageType;
        }
        
        // add isAuthenticated to all requests
        details.isAuthenticated = window.RESAAS && window.RESAAS.User2 && window.RESAAS.User2.UserId ? true : false;

        // add isEmailVerified to all requests
        details.isEmailVerified = window.RESAAS && window.RESAAS.User2 && window.RESAAS.User2.IsEmailVerified ? true : false;
        // add current experience & isPremium to all requests
        if (window.RESAAS && window.RESAAS.Environment) {
            details.experience = window.RESAAS.Environment.Experience;
        }
        if (window.RESAAS && window.RESAAS.User2) {
            details.isUltimate = !!window.RESAAS.User2.PremiumPlus;
            details.isPremium = !!window.RESAAS.User2.ReferralPro && !details.isUltimate;
            details.isBasic = !details.isPremium && !details.isUltimate;
        }
        if (!details.experience) {
            // if RESAAS.Environment.Experience is null assume user is in the resaas environment
            details.experience = "resaas";
        }

        details.experience = details.experience.toLowerCase();

        this.setOrigin(details);
        this.eventsTracked.push([name, details]);

        if (options !== null) {
            MixPanel.prototype.trackEventPromise(name, details).then(function (mp_retObj) {
                self.afterTrackEvent(callback, mp_retObj.success, mp_retObj.reference)
            }, function (err) {
                self.publish('loggly.error', { message: err });
            }).done(function () {
                window.location.assign(options.link);
            });
        } else {
            if (mixpanel.track) {
                mixpanel.track(name, details, $.proxy(this.afterTrackEvent, this, callback));
            }
            if (!this.enabled) {
                setTimeout($.proxy(this.afterTrackEvent, this, callback, 1, { event: name, properties: details }), 1);
                return;
            }
        }
        C('tracking call: ', name);
    };

    MixPanel.prototype.setPeople = function (props, callback) {
        this.personTracked.push(['set', props]);
        if (!this.enabled) return;
        mixpanel.people.set(props, callback);
    };

    MixPanel.prototype.incrementPeople = function (prop, add) {
        if (add == null || typeof add === 'undefined') add = 1
        this.personTracked.push(['increment', prop, add]);
        if (!this.enabled) return;
        mixpanel.people.increment(prop, add);
    };

    MixPanel.prototype.trackUserEmailSubscription = function (id, emailStatus, ExpressConsent) {
        if (!this.enabled) return;
        mixpanel.identify(id);
        if (ExpressConsent === undefined) {
            this.setPeople({
                'Email Status': emailStatus
            });
        } else {
            this.setPeople({
                'Email Status': emailStatus,
                'Express Consent': ExpressConsent
            });
        }
    };

    MixPanel.prototype.updateAccountSettings = function (params) {
        this.setPeople({
            $first_name: params.details.FirstName,
            $last_name: params.details.LastName,
            $email: params.details.Email,
            'mobile number': params.details.Phone,
            'Website Url': params.details.WebsiteUrl,
            'Office Name': params.details.OfficeDetails.Name
        });
    };

    MixPanel.prototype.subscriber = function (subscribe) {
        var self = this;
        // Use these generic events where possible
        subscribe('mixpanel.AddContent', function (ev, data) {
            self.trackEvent(EVENTS.CONTENT, data);
        });
        subscribe('mixpanel.Intent', function (ev, data) {
            self.trackEvent(EVENTS.INTENT, data);
        });
        subscribe('mixpanel.Delete', function (ev, data) {
            self.trackEvent(EVENTS.DELETE, data);
        });
        subscribe('mixpanel.Result', function (ev, data) {
            self.trackEvent(EVENTS.RESULT, data);
        });
        subscribe('mixpanel.Authentication', function (ev, data) {
            self.trackEvent(EVENTS.AUTHENTICATION, data);
        });
        subscribe('mixpanel.Search', function (ev, data) {
            self.trackEvent(EVENTS.SEARCH, data);
        });
        subscribe('mixpanel.Landed', function (ev, data) {
            self.trackEvent(EVENTS.LAND, data);
        });
        subscribe('mixpanel.NewAccount', function (ev, data) {
            self.trackEvent(EVENTS.REGISTER, data);
        });
        subscribe('mixpanel.Payment', function (ev, data) {
            self.trackEvent(EVENTS.PAYMENT, data);
        });
        //

        subscribe('upgrade.request', function (ev, data) {
            self.trackEvent(EVENTS.REGISTER, { 'type': data.type, 'page': data.page, 'requesters name': data.requestersName, 'requesters email': data.requestersEmail, 'admins name': data.adminsName, 'distinct_id': data.distinct_id });
        });
        subscribe('listing.data.successful', function (ev, data) {
            data.content = 'Listing Data';
            self.trackEvent(EVENTS.CONTENT, data);
        });
        subscribe('listing.sold.successful', function (ev, data) {
            data.content = 'Listing Sold';
            self.trackEvent(EVENTS.CONTENT, data);
        });
        subscribe('intent.createbutton.referral.click', function (ev, data) {
            self.trackEvent(EVENTS.INTENT, data);
        });

        subscribe('intent.createbutton.listing.click', function (ev, data) {
            self.trackEvent(EVENTS.INTENT, data);
        });
        subscribe('intent.createbutton.reblast.click', function (ev, data) {
            self.trackEvent(EVENTS.INTENT, data);
        });

        subscribe('relationship.requested', function (ev, data) {
            data.content = 'Relationship Requested';
            self.trackEvent(EVENTS.RELATIONSHIP, data);
        });
        subscribe('relationship.accepted', function (ev, data) {
            data.content = 'Relationship Accepted';
            self.trackEvent(EVENTS.RELATIONSHIP, data);
        });
        subscribe('relationship.dismissed', function (ev, data) {
            data.content = 'Relationship Dismissed';
            self.trackEvent(EVENTS.RELATIONSHIP, data);
        });
        subscribe('listing.create.intent', function (ev, data) {
            data.content = 'Listing Intent Create from Dashboard ';
            self.trackEvent(EVENTS.INTENT, data);
        });
        subscribe('listings.active', function (ev, data) {
                var mixpanelObj = {};
                mixpanelObj['Listings Active'] = data.Listings;
                if (mixpanel && mixpanel.people && mixpanel.people.set) {
                    mixpanel.people.set(mixpanelObj);
                }
        });
        subscribe('save.event.successful', function (ev, data) {
            self.trackEvent(EVENTS.CONTENT, data);
        });
        subscribe('delete.event.successful', function (ev, data) {
            self.trackEvent(EVENTS.DELETE, data);
        });
        subscribe('intent.create.event', function (ev, data) {
            self.trackEvent(EVENTS.INTENT, data);
        });
        subscribe('intent.delete.event', function (ev, data) {
            self.trackEvent(EVENTS.INTENT, data);
        });
        subscribe('decline.referral', function (ev, data) {
            self.trackEvent(EVENTS.CONTENT, data);
        });
        subscribe('intent.sendMessage.click', function (ev, data) {
            data.type = 'send message';
            self.trackEvent(EVENTS.INTENT, data);
        });
        //DO NOT add to this subscription list if a generic subscription will suffice
        //SEE TOP OF FUNCTION
    };

    MixPanel.prototype.publisher = function (publish) {
        this.publish = publish;
        if (window.pageType !== "OnboardWizardPage") {
            this.pageTracking();
        }
    };

    // reset page view tracking (for unit testing purpose only)
    MixPanel.prototype.resetPageTracking = function () {
        pageTracked = false;
    };

    // ubiquitous page view tracking
    MixPanel.prototype.pageTracking = function () {
        var path, visitStatus, $body, eventData, mpFromRegex, mpFrom, referralViewModel;

        if (pageTracked) {
            return false;
        }

        pageTracked = true;

        path = location.pathname;
        $body = $('body');
        mpFromRegex = /(?:mp_from=)([\w-_]+)/;
        mpFrom = window.location.search.match(mpFromRegex);

        if (path === '/activate') {
            this.trackEvent(EVENTS.LAND, { page: 'activation', type: 'landing' });
        }

        if (!$body.hasClass('component-homepage') && !$body.hasClass('component-activate-account')) {
            
            eventData = {
                page: window.location.pathname,
                type: window.pageType,
            };

            if (mpFrom) {
                eventData.from = mpFrom[1];
            }

            if (window.pageType === "referral-response" || window.pageType === "instant-referral-response") {
                referralViewModel = window.RESAAS.ReferralDetails || window.RESAAS.instantReferralDetails;

                if (referralViewModel.ReferralType === 2) { eventData["lead type"] = "Referral"; }
                if (referralViewModel.ReferralType === 3) { eventData["lead type"] = "Buyer Need"; }
                if (referralViewModel.ReferralType === 4) { eventData["lead type"] = "Instant Referral"; }
                eventData.context = (referralViewModel.FirmOrGroupId ? "firm or group" : "public");
                eventData.source = referralViewModel.ReferralId;
                // 12-hours paywall
                eventData["referral-delay-active"] = referralViewModel.AccessDelayMilisecondsRemaining !== 0;
                // award limits paywall
                eventData["referral-limit-active"] = parseInt(referralViewModel.NumberOfAwards) >= parseInt(referralViewModel.MaxNumberOfAwards);
                // user can respond
                eventData["referral-can-respond"] = referralViewModel.CanRespondToReferral === 'True';

                // special case if user is the referral owner or if this is not an open referral (ie. a buyerneed or instant referral) these should be undefined instead
                if (referralViewModel.ReferralType !== 2 || ("/" + referralViewModel.SenderDomain) === window.RESAAS.User2.DomainUrl) {
                    delete eventData["referral-delay-active"];
                    delete eventData["referral-limit-active"];
                    delete eventData["referral-can-respond"];
                }
            }

            if (RESAAS && RESAAS.hasOwnProperty('User2') &&  RESAAS.User2.UserId) {
                eventData["is owner"] = (window.isO === "True") ? "Owner" : "Visitor";

                this.trackEvent(EVENTS.LAND, eventData);

                if (window.pageType === "profile") {
                    if (RESAAS.User.currentUserId === RESAAS.User.targetUserId) {
                        this.userInfoTracking();
                    }
                }

            } else {
                eventData["is owner"] = "Logged Out User";
                this.trackEvent(EVENTS.LAND, eventData);
            }
        }

    };
    MixPanel.prototype.setUTMTracking = function (data) {
        mixpanel.people.set_once({
            "utm_source": data.Source,
            "utm_medium": data.Medium,
            "utm_content": data.Content,
            "utm_campaign": data.Campaign,
            "utm_term": data.Term
        });
    }
    MixPanel.prototype.userInfoTracking = function () {
        var mixpanelObj = {};
        var firmname = RESAAS.User2.FirmName;
        if (firmname != '') {
            mixpanelObj['Firm Name'] = firmname;
        }

        var phoneNum1, phoneNum2;
        if ($('.details-list li').length > 1) {
            var phoneType1 = $.trim($($('.details-list li')[0]).html().split(':')[0]);
            var phoneType2 = $.trim($($('.details-list li')[1]).html().split(':')[0]);

            if (phoneType1 === "Mobile Number") {
                phoneNum1 = $.trim($($('.details-list li')[0]).html().split(":")[1]);
                mixpanelObj['$phone'] = phoneNum1;
            }

            if (phoneType1 === "Office") {
                phoneNum2 = $.trim($($('.details-list li')[0]).html().split(":")[1]);
                mixpanelObj['Office Phone'] = phoneNum2;
            } else if (phoneType2 === "Office") {
                phoneNum2 = $.trim($($('.details-list li')[1]).html().split(":")[1]);
                mixpanelObj['Office Phone'] = phoneNum2;
            }

        }

        //check if environment.language exists > if no, check cookie > if no cookie, set default to english
        var userLanguage = 'en-US';

        if (typeof RESAAS.Environment.Language !== 'undefined') {
            userLanguage = RESAAS.Environment.Language;
        } else {
            var getCookies = document.cookie.split(';');

            for (var i = 0; i < getCookies.length; i++) {
                name = getCookies[i].split('=')[0];
                value = getCookies[i].split('=')[1];

                if (name === ' language') {
                    userLanguage = value;
                }
            }
        }

        mixpanelObj['$language'] = userLanguage;
        if (mixpanel && mixpanel.people && mixpanel.people.set) {
            mixpanel.people.set(
              mixpanelObj
            );
        }

    };

    MixPanel.prototype.cookieClear = function () {
        if (mixpanel && mixpanel.cookie) {
            mixpanel.cookie.clear();
        } else {
            this.clearCookieOnLoad = true;
        }
    };

    MixPanel.prototype.createAttrObject = function createAttrObject(attrs, attrPrefix) {
        var attrObj, attrPrefixLength, i, l;
        attrPrefixLength = attrPrefix.length
        attrObj = {};
        attrObj.mpObj = {};
        attrObj.mpObj.data = {};

        //look at all element attributes
        for (i = 0, l = attrs.length; i < l; i++) {
            //if element attribute matches data-mixpanel add to the mixpanel object
            if (attrs[i].name.indexOf(attrPrefix) === 0) {
                if (attrs[i].name === 'data-mixpanel-name') {
                    attrObj.mpObj[attrs[i].name.substr(attrPrefixLength)] = attrs[i].value
                }
                else {
                    attrObj.mpObj.data[attrs[i].name.substr(attrPrefixLength)] = attrs[i].value
                }
            }

            //capture the href if it is a link
            if (attrs[i].name === 'href') {
                attrObj.href = attrs[i].value;
            }

            // capture the target if it exists
            if (attrs[i].name === 'target') {
                attrObj.target = attrs[i].value;
            }

            // capture the download if it exists
            if (attrs[i].name === 'download') {
                attrObj.download = attrs[i].value;
            }
        };

        return attrObj;
    };

    MixPanel.prototype.validateHref = function validateHref(href) {
        var match;
        // check if string is empty, starts with # or starts with javascript:
        match = /^\s*$|^#|^javascript:/.test(href);
        // if match any of the above, href considered invalid, return false
        return !match;
    }

    MixPanel.prototype.trackEventFromDataAttr = function trackEventFromDataAttr(e) {
        var attrs, attrObj, attrPrefix, titleElement, titleAttrs, mpObj;

        // Prevent double tracking when form input/label is clicked. Skip 'click' event and only track 'change' event.
        if ((e.currentTarget.nodeName === 'INPUT') && e.type === 'click') {
            return
        }

        // get mixpanel properties from target element(specific properties) and title element(global properties)
        attrPrefix = 'data-mixpanel-';
        attrs = Array.prototype.slice.call(e.currentTarget.attributes);
        titleElement = document.getElementsByTagName('title');
        if (titleElement.length > 0) {
            titleAttrs = Array.prototype.slice.call(titleElement[0].attributes);
            attrs = titleAttrs.concat(attrs);
        }

        // loop through attributes, returns mixpanel properties object
        attrObj = this.createAttrObject(attrs, attrPrefix);
        mpObj = attrObj.mpObj;

        //not mixpanel, exit
        if (!mpObj['name']) {
            return;
        }

        //if link is for redirect, prevent default and do redirect in callback
        if (attrObj.href && this.validateHref(attrObj.href) && !(attrObj.target === '_blank') && !attrObj.download) {
            e.preventDefault();
            this.trackEvent(mpObj.name, mpObj.data, attrObj.href);
        } else {
        //if link is for new window/tab, download or no link exists
            this.trackEvent(mpObj.name, mpObj.data);
        }
    };

    // https://mixpanel.com/docs/integration-libraries/javascript-full-api#set_config
    //add your test account token at line 98
    var instance = new MixPanel({
        // should not be commited as true
        debug: false,
        // should not be commited as true
        test: false
    });

    instance.identify();

    return instance;
})();

