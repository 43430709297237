﻿/**
 * re_keywords_data
 * @module keywordsDatat
 * @author May Montazer
 */

define([],

	function () {
		'use strict';

		var data = [
			{
				"id": 1,
				"Name": "looking for an agent",
				"Link": "/r/create"
			},
			{
				"id": 2,
				"Name": "looking for a property",
				"Link": "/leads/create?type=buyerneed"
			},
			{
				"id": 3,
				"Name": "looking for a listing",
				"Link": "/leads/create?type=buyerneed"
			},
			{
				"id": 4,
				"Name": "looking for a pre-market",
				"Link": "/leads/create?type=buyerneed"
			},
			{
				"id": 5,
				"Name": "looking for a coming soon listing",
				"Link": "/leads/create?type=buyerneed"
			},
			{
				"id": 6,
				"Name": "agents",
				"Link": "/agents"
			},
			{
				"id": 5,
				"Name": "agent",
				"Link": "/agents"
			},
			{
				"id": 5,
				"Name": "referral",
				"Link": "/leads"
			},
			{
				"id": 6,
				"Name": "referrals",
				"Link": "/leads"
			},
			{
				"id": 7,
				"Name": "buyer",
				"Link": "/leads"
			},
			{
				"id": 8,
				"Name": "listing",
				"Link": "/listings"
			},
			{
				"id": 9,
				"Name": "listings",
				"Link": "/listings"
			},
			{
				"id": 10,
				"Name": "property",
				"Link": "/listings"
			}
		];

		var module = {};
		module.private = {};

		//API

		module.browse = function () {
			return data;
		};


		return module;
	}
);