import React from 'react';
import PropTypes from 'prop-types';
import _curry from 'ramda/src/curry';
import GeneralUtility from '../../utilities/generalUtility';

const Image = props => {
	const buildSrc = function buildSrc(path, format, quality, width, height, resize, density) {
		if (resize === false) {
			return path;
		}

		if (path && path.indexOf('?') > -1) {
			const customizedAttr = { quality: quality };
			customizedAttr.width = density ? width * density : width;
			customizedAttr.height = density ? height * density : height;

			if (format) {
				customizedAttr.format = format;
			}
			return GeneralUtility.addParamsToUrlQueryString(path, customizedAttr);
		}

		return `${path}?quality=${quality}&width=${Math.ceil(width * density)}${
			height ? `&height=${Math.ceil(height * density)}` : ''
		}${format ? `&format=${format}` : ''}`;
	};

	const src = _curry(buildSrc)(props.src, props.format, props.quality, props.width, props.height, props.resize);
	let classname = props.className;
	if (props.lazy) {
		classname = `${props.className || ""} lazyload`;
	}
	return (
		<React.Fragment>
			{props.lazy && (
				<img
					data-src={src(1)}
					data-srcset={`${src(1)} 1x, ${src(1.5)} 1.5x, ${src(2)} 2x`}
					alt={props.alt}
					className={classname}
					{...props.attributes}
				/>
			)}
			{!props.lazy && (
				<img
					src={src(1)}
					srcSet={`${src(1)} 1x, ${src(1.5)} 1.5x, ${src(2)} 2x`}
					alt={props.alt}
					className={classname}
					{...props.attributes}
				/>
			)}
		</React.Fragment>
	);
};

Image.propTypes = {
	alt: PropTypes.string,
	attributes: PropTypes.object,
	className: PropTypes.string,
	format: PropTypes.string,
	quality: PropTypes.number,
	resize: PropTypes.bool,
	src: PropTypes.string.isRequired,
	width: PropTypes.number,
	height: PropTypes.number,
	lazy: PropTypes.bool
};

Image.defaultProps = {
	quality: 80
};

export default Image;
