import _path from 'ramda/src/path';
import request from './AxiosRequest';
import filterOutEmailPassword from '../utilities/filterOutEmailPassword';

const add = (logglyLevel, message = 'service log', optional) => {

	const logglyToken = window.RESAAS.Environment ? window.RESAAS.Environment.lId : null;
    const o = optional ? ` ${JSON.stringify(optional)}` : '';

	const data = {
		LogLevel: logglyLevel,
		uAgent: window.navigator.userAgent,
		uInfo: window.RESAAS.User2 ? window.RESAAS.User2.UserId : null,
		href: window.location.href,
		tag: 'FE',
		Message: filterOutEmailPassword(`${message}${o}`)
	}

	const href = _path(["location", "href"], window);
	if ( href && (href.indexOf('local.resaas')>=0) ) {
		console.error('LOGGLY ERROR:', data); // eslint-disable-line no-console
	}

	return request({
		method: 'POST',
		url: `https://logs-01.loggly.com/inputs/${logglyToken}/tag/http`,
		data,
		headers: { 'content-type': 'text/plain' }
	});
}

const Loggly = {
	add
}

export default Loggly;
