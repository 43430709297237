import _path from 'ramda/src/path';

const add = (name, data) => {
    if (_path(['RESAAS', 'MixPanel', 'trackEvent'], window)) {
        window.RESAAS.MixPanel.trackEvent(name, data);
    }
};

const setPeople = data => {
    if (_path(['RESAAS', 'MixPanel', 'setPeople'], window)) {
        window.RESAAS.MixPanel.setPeople(data);
    }
};

const Mixpanel = {
    add,
    setPeople
};

export default Mixpanel;
