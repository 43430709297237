﻿/**
 * @fileOverview re_realtime_control.js
 *
 * @author May Montazer
 *
 * @events
 * Subscribe
 * uiAction.RSRealtimeSubscribe
 * A module to create a channel subscribtion on pusher
 */
define(['jquery', 'pubsub', 'generalUtilities', 'Pusher'], function realtimeControl(
	$,
	pubsub,
	generalUtilities,
	Pusher
) {
	'use strict';

	//#region set up
	var module, channels, pusher, settings, elements;
	module = {};
	module.private = {};
	module.properties = {};

	channels = {};

	elements = {};

	settings = {
		appKey: '66080d4cb5da403ae3b9',
		eventPrefix: 'Realtime',
		subscribeEvent: 'uiAction.RSRealtimeSubscribe'
	};
	//#endregion

	//#region utilities

	/**
	 * check / create the channel subscription
	 * @param {object} e the event.
	 * @param {object} Data passed in by pubsub event
	 * bind to the event
	 */

	module.private.handleRealtimeSubscribe = function handleRealtimeSubscribe(e, data) {
		if (data.channel && !channels[data.channel]) {
			channels[data.channel] = pusher.subscribe(data.channel);
			// bind to all events of the channel
			channels[data.channel].bind_global(module.private.handleMessage);
		}
	};

	/**
	 * publish RSRealtimeX event with data form pusher ex:RSRealtimeNewReblast
	 * @param {object} data object from Channel.
	 * pubsub.subscribe(data.RSRealtimeNewReblast)
	 */
	module.private.handleMessage = function handleMessage(e, data) {
		if (data.Event) {
			pubsub.publish(generalUtilities.stringFormat('data.Realtime.{0}', data.Event), data);

			// forward event to body for react to handle events as well
			elements.body.trigger(data.Event, [data]);
		}
	};

	//#endregion

	/**
	 * Initiates the module, creates a guid .
	 * @param {object} [e] The pubsub event, optional depending module is self init or by pubsub event.
	 * @param {object} [data] Data passed in by pubsub event, optional depending module is self init or by pubsub event.
	 */

	module.private.init = function init(e, data) {
		// module config
		module.properties.guid = data && data.guid ? data.guid : generalUtilities.uuid();

		// elements
		elements.body = $('body');

		// if the Pusher API does not exist exit or is re_module_stub
		if (!Pusher || ($.isPlainObject(Pusher) && $.isEmptyObject(Pusher))) {
			return false;
		}

		// create api instance
		pusher = new Pusher(settings.appKey);

		pubsub.subscribe('uiAction.RSRealtimeSubscribe', module.private.handleRealtimeSubscribe);

		return true;
	};

	module.private.init();

	// expose API
	return module;
});
