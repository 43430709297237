﻿define(['logglyVendor', 'pubsub'], function logglyController(logglyTracker, pubsub) {
    'use strict';

    /**
     * A module for logging errors on loggly.
     * @exports logglyController
     * @author May Montazer
     */

    var module;
    var uAgent = window.navigator.userAgent;
    var uInfo = RESAAS && RESAAS.User2 ? RESAAS.User2.UserId : null;
    var lID = RESAAS && RESAAS.Environment ? RESAAS.Environment.lId : null;
    var href = window.location.href;

    module = {};
    module.private = {};
    module.private.logger = new LogglyTracker();

    /**
     * sending uncaught errors to loggly
     * @param {object} [e] e event
     */
    module.private.errorEventHandler = function errorEventHandler(e) {
        var payload = {
            LogLevel: 'ERROR',
            href: href,
            tag: 'FE',
            UserAgent: uAgent,
            UserInfo: uInfo
        };

        payload.Column = e && e.colno ? e.colno : undefined;
        payload.Line = e && e.lineno ? e.lineno : undefined;
        payload.Message = e && e.error && e.error.message ? module.private.filterMessage(e.error.message) : undefined;
        payload.Stack = e && e.error && e.error.stack ? e.error.stack : undefined;

        module.private.logger.push(payload);
    };

    /**
     * sending info log to loggly
     * @param {object} [e] e event
     * @param {object} [logObj] message info
     */
    module.private.info = function info(e, logObj) {
        module.private.track('INFO', logObj);
    };

    /**
     * sending warn log to loggly
     * @param {object} [e] e event
     * @param {object} [logObj] message info
     */
    module.private.warn = function warn(e, logObj) {
        module.private.track('WARN', logObj);
    };

    /**
     * sending error log to loggly
     * @param {object} [e] e event
     * @param {object} [logObj] message info
     */
    module.private.error = function error(e, logObj) {
        module.private.track('ERROR', logObj);
    };

    /**
     * sends log to loggly
     * @param {object} [e] e event
     * @param {object} [logObj] message info
     */
    module.private.track = function track(level, logObj) {
        var m = module.private.filterMessage(logObj.message);

        if (href && href.indexOf('local.resaas') >= 0) {
            window.console.error(level + ':' + m); // jshint ignore:line
        }

        module.private.logger.push({
            LogLevel: level,
            Message: m,
            UserInfo: uInfo,
            UserAgent: uAgent,
            tag: 'FE',
            href: href
        });
    };

    /**
     * filtering message before sending them to loggly
     * @param {object} message
     */
    module.private.filterMessage = function filterMessage(m) {
        var message = m;

        if (!message || typeof message !== 'string') {
            return '';
        }

        message = message.replace(/\"Email\":\"(.*?)\@(.*?)\.(.*?)\"/gi, '');
        message = message.replace(/[A-Z0-9._%+-.*?]+\@(.*?)\.[A-Z0-9._%+-.*?]+/gi, '');
        message = message.replace(/\"Password\":\"(.*?)\"/gi, '');

        return message;
    };

    pubsub.subscribe('loggly.error', module.private.error);
    pubsub.subscribe('loggly.warning', module.private.warn);
    pubsub.subscribe('loggly.info', module.private.info);

    if (module.private.logger && lID) {
        module.private.logger.push({ logglyKey: window.RESAAS.Environment.lId });
        window.addEventListener('error', module.private.errorEventHandler);
    }

    return module;
});
