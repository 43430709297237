import React, { Component, createContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import LogglyLogger from '../../API/Loggly';
import resx from '../../utilities/resx';
import { RESX_GLOBAL, LOGGLY_LEVEL_ERROR } from '../../Constants';

const MessageContext = createContext();

const Message = props => (
	<div className={`re-message ${props.type ? `re-message-${props.type}` : ''}`} id={props.domId} data-guid={props.guid}>
		<a className="re-message-control re-message-control-delete" onClick={() => props.remove(props.guid)}>
			{resx(RESX_GLOBAL, 'EDITABLE_CANCEL')}
		</a>
		<span dangerouslySetInnerHTML={{ __html: props.message }} />
	</div>
);

Message.propTypes = {
	message: PropTypes.string.isRequired,
	remove: PropTypes.func.isRequired,
	guid: PropTypes.string.isRequired,
	type: PropTypes.string,
	domId: PropTypes.string
};

class MessageProvider extends Component {
	constructor() {
		super();
		this.state = {
			root: null,
			messages: []
		};
		this.messageId = 0;
		this.addMessage = this.addMessage.bind(this);
		this.removeMessage = this.removeMessage.bind(this);
	}

	static propTypes = {
		children: PropTypes.node
	};

	removeMessage(guid) {
		const message = this.state.messages.find(a => a.guid === guid);
		if (message && message.timeout) {
			clearTimeout(message.timeout);
		}

		this.setState(prevState => ({
			messages: prevState.messages.filter(a => a.guid !== guid)
		}));
	}

	addMessage(data) {
		const message = {};
		const list = [];
		message.guid = (this.messageId++).toString();
		message.message = data.message;
		message.type = data.type;
		message.autoDelete = data.autoDelete === false ? data.autoDelete : true;
		message.perpetuate = data.perpetuate === false ? data.autoDelete : true;
		message.domId = `re-message-${message.guid}`;
		message.targetAction = 'append';
		list.push(message);

		localStorage.setItem('reMessages', JSON.stringify(list));

		if (window.Modernizr && window.Modernizr.localstorage) {
			setTimeout(() => {
				localStorage.removeItem('reMessages');
			}, 5000);
		}

		if (message.autoDelete) {
			message.timeout = setTimeout(() => {
				this.removeMessage(message.guid);
			}, 5000);
		}

		this.setState(prevState => ({
			messages: [...prevState.messages, message]
		}));

		return message.guid;
	}

	componentDidMount() {
		const existingRoot = document.querySelector('.js-message-root');
		const existingContainer = document.querySelector('.re-message-container');

		const container = existingContainer || document.createElement('div');
		const root = existingRoot || document.createElement('div');

		if (!existingRoot) {
			root.className = 'js-message-root';
			container.appendChild(root);
		}

		if (!existingContainer) {
			container.className = 're-message-container';
			document.body.appendChild(container);
		}

		this.setState({ container, root });
	}

	componentWillUnmount() {
		const { messages } = this.state;

		messages.forEach(a => {
			clearTimeout(a.timeout);
		});

		// we are not removing the root from the container because it will break the component if multiple components are using the MessageProvider
		// once the reblast is deleted, it unmounts and clears all the messages associated with it which doesn’t allow the user to click the ‘x' because the message won’t exist.
		// the clean up would be done automatically.if a error message appears and the reblast associated with the container still exists, the user can still click the 'x'
	}

	render() {
		const { children } = this.props;
		const { root } = this.state;
		const messageAPI = { addMessage: this.addMessage, removeMessage: this.removeMessage };
		return (
			<MessageContext.Provider value={messageAPI}>
				{children}
				{root &&
					createPortal(
						this.state.messages.map(message => (
							<Message
								key={message.guid}
								guid={message.guid}
								message={message.message}
								type={message.type}
								remove={this.removeMessage}
							/>
						)),
						root
					)}
			</MessageContext.Provider>
		);
	}
}

const withMessage = WrappedComponent => {
	const WithMessage = props => (
		<MessageContext.Consumer>
			{message => {
				if (!message) {
					LogglyLogger.add(
						LOGGLY_LEVEL_ERROR,
						`REACT ERROR: Message Consumer missing Provider ${window.location.href}`
					);
				}
				return <WrappedComponent {...props} message={message} />;
			}}
		</MessageContext.Consumer>
	);
	return WithMessage;
};

export { withMessage, MessageProvider };
