﻿define(['jquery', 'pubsub', 'generalUtilities'], function toolbarControl($, pubsub, generalUtilities) {
    'use strict';

    var module, elements;

    module = {};
    module.private = {};

    elements = {
        body: $('body'),
        window: $(window),
        pageWrapper: $('.re-page-wrapper'),
        broadcastBanner: null
    };

    module.private.showNav = function showNav(e) {
        e.preventDefault();
        $('html').toggleClass('re-show-nav');
    };

    module.private.login = function login(e) {
        var returnUrl, queryParams, noReturnPageTypes, destination;
        e.preventDefault();
        destination = '/login';
        noReturnPageTypes = [
            'resetPassword',
            'Approved Supplier Program',
            'RESAAS pay',
            'What We Do',
            'integrations',
            'select-language',
            'signup-page',
            'verification-pending',
            'verify-email'
        ];

        if (!noReturnPageTypes.includes(window.pageType)) {
            queryParams = new URLSearchParams(window.location.search);

            if (queryParams.get('returnUrl')) {
                // if the query string includes a return url then just copy that
                returnUrl = queryParams.get('returnUrl');
            } else {
                // otherwise grab the whole url
                returnUrl = generalUtilities.stringFormat('{0}{1}', window.location.pathname, window.location.search);
            }

            // go to the login page with destination set
            destination = generalUtilities.stringFormat('/login?ReturnUrl={0}', encodeURIComponent(returnUrl));
        }

        generalUtilities.redirect(destination);
    };

    module.private.handleScroll = function handleScroll() {
        if (elements.window.scrollTop() > 20) {
            elements.body.addClass('re-broadcast-banner-hidden');
        } else {
            elements.body.removeClass('re-broadcast-banner-hidden');
        }
    };

    module.private.handleResizeForBroadcastBanner = function handleResizeForBroadcastBanner() {
        if (elements.broadcastBanner) {
            elements.pageWrapper.css('top', elements.broadcastBanner[0].offsetHeight);
        }
    };

    module.private.handleClickBroadcastBanner = function handleClickBroadcastBanner(e) {
        var href;

        href = $(e.currentTarget)
            .find('a')
            .attr('href');

        if (href) {
            e.preventDefault();
            pubsub.publish('mixpanel.Intent', { type: 'broadcast banner click' });

            setTimeout(function redirectTimeout() {
                generalUtilities.redirect(href);
            }, 500);
        }
    };

    module.private.setupBroadcastBanner = function setupBroadcastBanner() {
        var isBroadcastBannerEnabled,
            targetPageTypes,
            broadcastBannerResourceKey,
            broadcastBannerText,
            isAuthenicated,
            onTargetPage,
            resx,
            html;

        isBroadcastBannerEnabled = generalUtilities.prop(window, 'RESAAS.Environment.IsBroadcastBannerEnabled');

        if (!isBroadcastBannerEnabled) {
            return;
        }

        targetPageTypes = ['profile', 'activity-feed', 'listing-detail', 'notification-history', 'reblast-detail'];
        broadcastBannerResourceKey = generalUtilities.prop(window, 'RESAAS.Environment.BroadcastBannerResourceKey');
        broadcastBannerText = generalUtilities.prop(window, 'RESAAS.Environment.BroadcastBannerText');
        isAuthenicated = generalUtilities.prop(window, 'RESAAS.User2.UserId');
        onTargetPage = targetPageTypes.indexOf(window.pageType) >= 0;

        resx = broadcastBannerResourceKey
            ? generalUtilities.prop(
                  window,
                  generalUtilities.stringFormat('RESAAS.Localization.Global.{0}', broadcastBannerResourceKey)
              )
            : broadcastBannerText;
        html = generalUtilities.stringFormat(
            '<div class="js-broadcast-banner re-broadcast-banner"><p>{0}</p></div>',
            resx
        );

        if (resx && html && isAuthenicated && onTargetPage) {
            $('.re-page-wrapper').before(html);
            elements.broadcastBanner = $('.js-broadcast-banner');
            elements.broadcastBanner.on('click', module.private.handleClickBroadcastBanner);
            module.private.handleResizeForBroadcastBanner();
            elements.window.on('resize', module.private.handleResizeForBroadcastBanner);
            elements.window.on('scroll', module.private.handleScroll);
        }
    };

    module.private.init = function init() {
        module.private.setupBroadcastBanner();

        // element bindings
        elements.body
            .off('click.toolbarControlShowNav')
            .on('click.toolbarControlShowNav', '.js-page-toolbar-control-link', module.private.showNav);
        elements.body
            .off('click.toolbarControlLogin')
            .on('click.toolbarControlLogin', '.js-nav-login', module.private.login);
    };

    module.private.init();

    return module;
});
