﻿/**
 * re_mixpanel_control
 * @module mixpanelControl
 * @author Matt Foulger
 */

define(['pubsub', 'mixpanel', 'logglyController'], function mixpanelControl(pubsub) {
	// set up
	// -------------------------------------------

	'use strict';

	var module;

	module = {};
	module.private = {};

	// utilities
	//-------------------------------------------

	// event handlers
	//-------------------------------------------

	module.private.init = function init() {
		// config mixpanel
		RESAAS.MixPanel.subscriber(pubsub.subscribe);
		RESAAS.MixPanel.publisher(pubsub.publish);
		RESAAS.MixPanel.publishLocalStorage(pubsub.publish);
	};

	// external event bindings
	//-------------------------------------------

	module.private.init();

	// expose API
	//-------------------------------------------
	return module;
});
