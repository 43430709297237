﻿define(function () {
	'use strict';

	var module = {};

	//public methods
	//-------------------------------------------

	module.cleanOptions = function (ajaxOptions) {

		//remove after jquery upgrade
		if (ajaxOptions.method) {
			ajaxOptions.type = ajaxOptions.method;
			ajaxOptions.method = '';
		}

		if (ajaxOptions.type.toUpperCase() === 'POST' && ajaxOptions.data && typeof ajaxOptions.data !== 'string') {
			ajaxOptions.data = JSON.stringify(ajaxOptions.data);
		}

		return ajaxOptions;
	};

	module.createAjaxOptions = function (config, data) {
		var ajaxOptions;

		ajaxOptions = {};

		ajaxOptions.method = config.method || 'POST';
		ajaxOptions.contentType = config.contentType || 'application/json; charset=UTF-8';
		ajaxOptions.dataType = config.dataType || 'json';

		ajaxOptions.url = config.url || '';
		ajaxOptions.data = data || {};

		return ajaxOptions;
	};

	module.convertEventNameToResponseName = function (e) {
		return e.replace(/uiNeeds|uiHas/, 'data');
	};

	return module;

});