﻿;(function messageControl(root, factory) {
	if (typeof define === 'function' && define.amd) {
		define(['jquery', 'pubsub', 'Handlebars', 'generalUtilities', 'Sha1'], factory);
	} else {
		root.reMessageControl2 = factory(root.jQuery, root.PubSub, root.Handlebars, root.reGeneralUtilities, root.Sha1);
	}
}(this, function messageControl($, pubsub, Handlebars, generalUtilities, Sha1) {
	'use strict';

	var module, elements, values, settings, internalStorage, messageTemplate, watchList, countList;

	module = {};
	module.private = {};

	elements = {};
	values = {};
	settings = {};
	watchList = {};
	countList = {};

	settings.localStorageKey = 'reMessages';
	settings.domIdPrefix = 're-message-';
	settings.containerClass = 're-message-container';
	settings.loadingClass = 're-message-loading';

	settings.retryClass = 'js-message-retry';
	settings.deleteClass = 'js-message-delete';

	settings.autoDeleteMessageDelay = 5000;
	settings.deleteOptions = {};
	settings.deleteOptions.duration = 2000;
	settings.deleteOptions.easing = 'swing';
	settings.deleteOptions.complete = function () {
		$(this).remove();
	};

	messageTemplate = [
		'<div class="re-message re-message-{{type}}" id={{domId}} data-guid="{{guid}}">',
			'<a href="#" class="', settings.deleteClass,' re-message-control re-message-control-delete">', RESAAS.Localization.Global.EDITABLE_CANCEL, '</a>',
			'{{#if retry.count}}({{retry.count}}) {{/if}}',
			'{{{message}}}',
			'{{#if retry}}',
				' | ',
				'<a href="#" class="', settings.retryClass, ' re-message-control re-message-control-retry">', RESAAS.Localization.Global.MESSAGE_CONTROL_RETRY, '</a>',
			'{{/if}}',
		'</div>'
	].join('');

	module.private.message = function message(o) {
		var m;

		if (typeof o !== 'object' || !o.message) {
			return false;
		}

		m = o;

		// trim retry data to just request if request exists (standard behavior is to pass all data but only retry with request object)
		if (o.retry && o.retry.data && o.retry.data.request) {
			o.retry.data = o.retry.data.request;
		}

		// if type is set use, else if retry is set default to error, else default to message
		m.type =			(o.hasOwnProperty('type')) ? o.type : (o.hasOwnProperty('retry')) ? 'error' : 'message';
		m.perpetuate =		(o.hasOwnProperty('perpetuate')) ? o.perpetuate : false;
		// if autoDelete is set use, else if retry is set default to false, eles default to true
		m.autoDelete =		(o.hasOwnProperty('autoDelete')) ? o.autoDelete : o.hasOwnProperty('retry') ? false : true;
		// if guid is set use, else if retry is set default to Sha of data, else generate guid 
		m.guid =			(o.hasOwnProperty('guid')) ? o.guid : (o.hasOwnProperty('retry')) ? module.private.eventDataToHash(o.retry.watch, o.retry.data) : generalUtilities.uuid();
		m.targetAction =	(o.hasOwnProperty('targetAction')) ? o.targetAction : 'append';

		m.domId =			settings.domIdPrefix + m.guid;

		return m;
	}

	module.private.getMessages = function getMessages () {
		return internalStorage;
	};

	module.private.setMessages = function setMessages(list) {
		var listAsString;
	
		if (window.Modernizr && window.Modernizr.localstorage) {
			listAsString = JSON.stringify(list);
			localStorage.setItem(settings.localStorageKey, listAsString);
		}
	
		internalStorage = list;
	};

	module.private.deleteMessage = function deleteMessage(guid, instant) {
		var list, i, l, id, action;

		list = module.private.getMessages();
		instant = instant || false;

		for (i = 0, l = list.length; i < l; i++) {
			if (list[i].guid === guid) {
				id = list[i].domId;
				list.splice(i, 1);
				break;
			}
		}
	
		module.private.setMessages(list);
		
		if (instant) {
			$('#' + id).remove();
		} else {
			$('#' + id).fadeOut(settings.deleteOptions);
		}
	};

	module.private.deleteAllMessages = function deleteAllMessages() {
		var list;
		list = module.private.getMessages();
		list = [];
		module.private.setMessages(list);
		if (elements.container) {
			elements.container.html('');
		}
	};

	module.private.addMessage = function addMessage(m) {
		var list;
	
		// get the list of messages
		list = module.private.getMessages();

		// if retry count this message instance
		if (m.hasOwnProperty('retry')) { 
			// increment count
			countList[m.guid] = (countList[m.guid]) ? countList[m.guid] + 1 : 1;

			// add count to message for rendering if more than 1
			m.retry.count = (countList[m.guid] > 1) ? countList[m.guid] : false;
		}

		list.push(m);

		module.private.setMessages(list);
	};

	module.private.eventDataToHash = function eventDataToHash(e, data) {
		var eventName, eventData, hashString;

		eventName = e || 'eventName';
		eventData = data || {};

		hashString = [eventName, JSON.stringify(eventData)].join('');

		return Sha1.hash(hashString);
	};

	module.private.getMessageIndex = function getMessageIndex(guid) {
		var i, l, list, found;

		found = false;
		list = module.private.getMessages();

		for (i = 0, l = list.length; i < l; i++) {
			if (list[i].guid === guid) {
				found = true;
				break;
			}
		}

		return (found) ? i : -1;
	};

	module.private.getMessage = function getMessage(guid) {
		var list, m, i, l;
	
		list = module.private.getMessages();
		m = false;

		for (i = 0, l = list.length; i < l; i++) {
			if (list[i].guid === guid) {
				m = list[i];
				break;
			}
		}
	
		return m;
	};

	module.private.watchForRetry = function watchForRetry(m) {
		watchList[m.guid] = pubsub.subscribe(m.retry.watch, function (e, data) {
			var guid, m, d;

			// reduce data to request if it exists
			d = (data && data.request) ? data.request : data;

			// hash the request object, same as guid for retry events
			guid = module.private.eventDataToHash(e, d);
			m = module.private.getMessage(guid);

			if (m) {
				pubsub.unsubscribe(watchList[guid]);
				module.private.deleteMessage(guid, true);
			}
		});
	};

	module.private.setupMessageContainer = function setupMessageContainer() {
		elements.container = $('.' + settings.containerClass);

		if (!elements.container.length) {
			elements.container = $('<div class="' + settings.containerClass + '"></div>');
			elements.body.append(elements.container);
		}
	};

	// Display Messages

	module.private.injectMessage = function injectMessage(m) {
        var html, $container;

		// if message already exists delete it 
        $('#' + m.domId).remove();

        // harmonize encoding
        m.message = m.message.replace(/\\"/g, '"');

		html = values.messageTemplate(m);

		// confirm container exists
		module.private.setupMessageContainer();

		// inject to container
		$container = (m.hasOwnProperty('targetSelector')) ? $(m.targetSelector) : elements.container;
		$container[m.targetAction](html);
	
	};

	module.private.autoDeleteMesssage = function autoDeleteMesssage(m) {
		setTimeout(function () {
			module.private.deleteMessage(m.guid);
		}, settings.autoDeleteMessageDelay)
	};

	// Primary Actions

	// show message immediately
	module.private.show = function show(m) {
		module.private.injectMessage(m);
	
		// if auto delete is true, setup timeout to remove
		if (m.autoDelete) {
			module.private.autoDeleteMesssage(m);
		}

		// if retry is true, setup watch to remove
		if (m.retry) {
			module.private.watchForRetry(m);
		}
	};

	// delayed message are not perpetuated, cannot see a use case for this
	// module.private.delay = function (m) {
	// 	timers[m.guid] = setTimeout(function() {
	// 		module.injectMessage(m);
	// 		if (m.autoDelete) {
	// 			module.private.autoDeleteMesssage(m);
	// 		}
	// 	}, m.delay.time)
	// };

	// decide what kind of message this is and what action to take
	module.private.publish = function publish(m) {
	
		module.private.addMessage(m);
	
		// if (m.hasOwnProperty('delay')) {
			// module.private.delay(m);
		// } else {
			module.private.show(m);
		// }
	};

	// Event Handlers

	module.private.handleMessageAdd = function handleMessageAdd(e, data) {
		var m, guid;

		//  create the message object
		m = module.private.message(data);

		//  clear the message before we add a new one
		if (m.clearPreviousMessage) {
			guid = $('.re-message').attr('data-guid');
			module.private.deleteMessage(guid, true);
		}

		if (m) {
			module.private.publish(m);
		}
	};

	module.private.handleMessagesDelete = function (e, guid) {
		module.private.deleteAllMessages();
	};

	module.private.handleMessageDeleteClick = function handleMessageDeleteClick(e) {
		var guid;
	
		e.preventDefault();
		guid = $(e.target).closest('.re-message').attr('data-guid');
	
		module.private.deleteMessage(guid, true);
	};

	module.private.handleMessageRetryClick = function handleMessageRetryClick(e) {
		var $target, guid, m;
	
		e.preventDefault();

		$target = $(e.target).closest('.re-message');

		// get guid and the message
		guid = $target.attr('data-guid');
		m = module.private.getMessage(guid);

		// set message to loading state
		$target.addClass(settings.loadingClass);

		// re publish message event
		pubsub.publish(m.retry.e, m.retry.data);
	};

	module.private.init = function init() {
		var list, i, l;
	
		elements.html = $('html');
		elements.body = $('body');

		// watch for delete clicks
		elements.html.on('click', '.' + settings.deleteClass, module.private.handleMessageDeleteClick);

		// watch for retry clicks
		elements.html.on('click', '.' + settings.retryClass, module.private.handleMessageRetryClick);
		
		// compile and cache message template
		values.messageTemplate = Handlebars.compile(messageTemplate);
	
		// clear internal storage
		internalStorage = [];

		// get messages from localstorage
		if (window.Modernizr && window.Modernizr.localstorage) {
			list = window.localStorage.getItem(settings.localStorageKey)
			list = JSON.parse(list);
		}
	
		// display all existing, perpetuating messages
		if (list && list.length) {
			for (i = 0, l = list.length; i < l; i++) {
				if (list[i].hasOwnProperty('perpetuate') && list[i].perpetuate) {
					delete list[i].perpetuate;
					module.private.publish(list[i]);
				}
			}
		}
	};

	module.private.init();

	/**
	* @param {string} data.message to be displayed
	* @param {string} [data.type=message] style of message
	* @param {boolean} [data.perpetuate=false] will the message show on the next page
	* @param {boolean} [data.autoDelete=true] message will auto delete, defaults to false for messages with retry
	* @param {string} [data.guid] guid for future reference
	* @param {string} [data.targetSelector] DOM selector of where to send the message
	* @param {string} [data.targetAction=append] DOM method
	* @param {object} [data.delay.e] event on which to cancel message
	* @param {int} [data.delay.time] amount of time by which to delay message publication in miliseconds
	* @param {object} [data.retry] retry event config
	* @param {string} [data.retry.e] event to publish on retry
	* @param {object} [data.retry.data] data to attach to retry event
	* @param {string} [data.retry.watch] event to watch for and remove message
	*/

	pubsub.subscribe('uiAction.MessageAdd', module.private.handleMessageAdd);
	pubsub.subscribe('uiAction.MessagesDelete', module.private.handleMessagesDelete);

	if(generalUtilities.prop(window, 'mojo.Messaging.subscribe')) {
		mojo.Messaging.subscribe('uiAction.MessageAdd', module.private.handleMessageAdd);
	}

	return module;

}));