﻿(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        define(['jquery', 'pubsub', 'keywordsData'], factory);
    } else {
        root.reGeneralUtilities = factory(root.jQuery, root.PubSub);
    }
})(this, function reGeneralUtilities($, pubsub, keywordsData) {
    'use strict';
    /**
     * A module for general utilities.
     * @exports generalUtilities
     */
    var module;

    module = {};
    module.private = {};

    /**
     * Returns true if file has risky filetype.
     * @param {string} name Filename with extension
     * @return {boolean}
     * @example
     * generalUtilities.riskyFiles('test.exe')
     */
    module.riskyFiles = function(name) {
        if (!name) return false;
        var filExt = name.split('.').pop();
        return (/^(ADE|ADP|APP|ASA|ASP|BAS|BAT|CER|CHM|CMD|COM|CPL|CRT|CSH|DLL|EXE|FXP|HLP|HTA|HTM|HTML|HTR|INF|INS|ISP|ITS|JS|JSE|KSH|LNK|MAD|MAF|MAG|MAM|MAQ|MAR|MAS|MAT|MAU|MAV|MAW|MDA|MDB|MDE|MDT|MDW|MDZ|MHT|MHTM|MHTML|MSC|MSI|MSP|MST|OCX|OPS|PCD|PIF|PRF|PRG|REG|SCF|SCR|SCT|SHB|SHS|TMP|URL|VB|VBE|VBS|VBX|VSMACROS|VSS|VST|VSW|WS|WSC|WSF|WSH|XSL)$/gi).test(
            filExt
        );
    };

    //from http://stackoverflow.com/a/2117523
    //chance of collisions is extremely low but not guaranteed
    /**
     * Create guid.
     * @return {string}
     */
    module.uuid = function() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    /**
     * Returns the file extension.
     * @param {string} path filename or filepath
     * @return {string}
     */
    module.getFileExtension = function(path) {
        if (typeof path === 'string') {
            return path.split('.').reverse()[0];
        }

        return '';
    };

    /**
     * Returns the filename from filepath.
     * @param {string} path filepath
     * @return {string}
     */
    module.getFileName = function(path) {
        if (typeof path === 'string') {
            return path.split('/').reverse()[0];
        }

        return '';
    };

    /**
     * make dynamic strings base on required arguments.
     * @example
     * generalUtilities.stringFormat('First {0} Last','In between' );
     */
    module.stringFormat = function(format) {
        var args;
        if (typeof format !== 'string') {
            return false;
        }
        args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/\{(\d+)\}/g, function(match, number) {
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    };

    /**
     * Returns a simplified version of the language code
     * @param {string} lang language code to simplify
     * @return {string}
     */
    module.simplifyLanguageCode = function(lang) {
        return lang ? lang.match(/^[a-z|A-Z]*/)[0].toLowerCase() : lang;
    };

    /**
     * Transforms a query string into a JSON object.
     * @param {string} queryString - the string to transform (optional)
     * @return {object}
     */
    module.queryStringToObject = function(queryString) {
        var qs, qsa, qso, ia, k, v, x, l;
        qs = queryString || window.location.search;
        qso = {};
        qs = qs.substr(1);
        qs = qs.replace(/\+/g, ' ');
        qsa = qs.split('&');

        for (x = 0, l = qsa.length; x < l; x++) {
            if (qsa[x]) {
                ia = qsa[x].split('=');
                qso[ia[0]] = ia[1];
            }
        }

        return qso;
    };

    /**
     * Transforms a query string into a JSON object. Does NOT replace '+' with ' '.
     * @param {string} queryString - the string to transform (optional)
     * @return {object}
     */
    module.queryStringToObjectPlus = function(queryString) {
        var qs, qsa, qso, ia, k, v, x, l;

        qs = queryString || window.location.search;
        qso = {};
        qs = qs.substr(1);
        qsa = qs.split('&');

        for (x = 0, l = qsa.length; x < l; x++) {
            if (qsa[x]) {
                ia = qsa[x].split('=');
                qso[ia[0]] = ia[1];
            }
        }

        return qso;
    };

    /**
     * Transforms a query string into a JSON object.
     * @param {object} object - the object to transform
     * @return {string}
     */
    module.objectToQueryString = function(object) {
        var key, string;

        string = '';

        for (key in object) {
            if (object.hasOwnProperty(key)) {
                string += [key, '=', object[key], '&'].join('');
            }
        }

        return string;
    };

    /**
     * Query string object outputted to dom
     * @param {object} object - the object to transform
     */
    module.queryStringObjectToDom = function(object) {
        var key, selector;

        for (key in object) {
            selector = document.querySelector('input[name="' + key + '"]');

            if (object.hasOwnProperty(key)) {
                if (selector) {
                    selector.value = object[key];
                }
            }
        }
    };

    /**
     * Adds query string params to a url
     * @param {string} url - url that needs an updated query string
     * @param {object} object - the object to be transformed and added to query string
     * @return {string}
     */
    module.addParamsToUrlQueryString = function(url, object) {
        var oldObject, newQueryString, oldQueryString, object, queryStringIndex, hashIndex, hashUrl;
        hashUrl = '';
        queryStringIndex = url.indexOf('?');
        hashIndex = url.indexOf('#');

        if (hashIndex > -1) {
            hashUrl = url.slice(hashIndex);
            url = url.slice(0, hashIndex);
        }

        if (queryStringIndex > -1) {
            oldQueryString = url.slice(queryStringIndex);
            url = url.slice(0, queryStringIndex);
            oldObject = module.queryStringToObjectPlus(oldQueryString);
            object = $.extend(oldObject, object);
        }

        newQueryString = module.objectToQueryString(object);
        return [url, '?', newQueryString, hashUrl].join('');
    };

    /**
     * Get the localized resource.
     * @param {string} s The localized key
     * @return {string}
     */
    module.localizeResource = function(s) {
        var rArray, lib, key;

        rArray = s.split('.');
        lib = rArray.length > 1 ? rArray[0] : 'Global';
        key = rArray.length > 1 ? rArray[1] : rArray[0];

        if (window.RESAAS.Localization[lib] && window.RESAAS.Localization[lib][key]) {
            return window.RESAAS.Localization[lib][key];
        }

        return '';
    };

    /**
     * Format price with commas or decimal places to integer.
     * @param {string} price price
     * @return {string}
     */
    module.formatPriceToInt = function(price) {
        return (/(\.\d{1,2}|\,\d{1,2})$/g.exec(price) === null)
            ? price.replace(/(\.|\,|\s)/g, '')
            : (price.slice(0, /(\.\d+|\,\d+)$/g.exec(price)['index'])).replace(/(\.|\,|\s)/g, '');
    };

    /**
     * Disable a link/button as well as the parent form if it exists.
     * @param {string|Object[]} selector - The buttons selector or jQuery object or dom element
     * @param {string} eventName - The name of the event to re-enable on
     * @param {string} [className=submitting] - The className to add and then remove from the link/button
     */
    module.disableAndWaitFor = function(selector, eventName, className) {
        var $el, $form, className, eHandlers, eHandler, fHandlers, fHandler, token;

        $el = selector instanceof $ ? selector : $(selector);
        if (!$el || !$el.length || !eventName || !eventName.length) {
            return false;
        }

        $form = $el.closest('form');

        className = className || 'submitting';

        //TODO: add guid event filtering

        //TODO:
        //determine event type click for buttons/links change for select/checkbox/radio

        $el.addClass(className);
        $el.on('click.disableAndWaitFor', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
        });

        //TODO:
        //if event type is change then also add disabled property

        //move new binding to first position so stopImmediatePropagation will work
        eHandlers = $._data($el[0], 'events')['click'];
        eHandler = eHandlers.pop();
        eHandlers.splice(0, 0, eHandler);

        //disable form submit
        if ($form.length) {
            $form.on('submit.disableAndWaitFor', function(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
            });

            //move new binding to first position so stopImmediatePropagation will work
            fHandlers = $._data($form[0], 'events')['submit'];
            fHandler = fHandlers.pop();
            fHandlers.splice(0, 0, fHandler);
        }

        token = pubsub.subscribe(eventName, function(e) {
            $el.removeClass(className);
            $el.off('click.disableAndWaitFor');
            if ($form.length) {
                $form.off('submit.disableAndWaitFor');
            }
            pubsub.unsubscribe(token);
        });

        return $el;
    };

    /**
     * Builds profile image path.
     * @param {number} id - target users WebsiteId
     * @param {string} [size] - thumbnail | medium | large
     * @param {object} [o] - image resizer options
     * @param {string} [o.format=jpg] - image format
     * @param {number} [o.width=32] - image width
     * @param {number} [o.quality=80] - image quality
     * @return {string}
     */
    module.buildProfileImagePath = function(id, size, o) {
        var fakeArr, path, options, chunks, loopage, self, contentUrl, cdn, nocdn;

        fakeArr = [];
        path = '';
        options = {
            format: 'jpg',
            quality: 80,
            width: 32
        };

        cdn = module.prop(RESAAS, 'Environment.ContentUrl');
        nocdn = module.prop(RESAAS, 'Environment.ContentUrlNoCDN');

        if (typeof id == 'undefined' || typeof id !== 'number' || !cdn || !nocdn) {
            return false;
        }

        //if size is a string
        if ('string' === typeof size) {
            if (size === 'large') {
                options.width = 228;
            } else if (size === 'medium') {
                options.width = 64;
            }
        }

        //if options set, get them
        if ('object' === typeof o) {
            $.extend(options, o);
        }

        //if options is not set, but size contains the options
        if ('object' === typeof size && !o) {
            $.extend(options, size);
        }

        function addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        }

        chunks = (function getRelPath(id) {
            if (!id) id = 0;
            id = addCommas(id);
            id = id.split(',');
            return id;
        })(id);

        loopage = 3 - chunks.length;

        for (var i = 0; i < loopage; i++) {
            fakeArr.push(0);
        }

        for (var x = 0; x < chunks.length; x++) {
            chunks[x] = parseInt(chunks[x], 10);
            fakeArr.push(chunks[x]);
        }

        for (var i = 0; i < fakeArr.length; i++) {
            path += fakeArr[i] + '/';
        }

        self = id === module.prop(RESAAS, 'User2.WebsiteId');
        contentUrl = self ? nocdn : cdn;
        path = [contentUrl, 'assets/realtors/website/', path, 'profile.png'].join('');

        if (!self) {
            path = [path, '?', module.objectToQueryString(options)].join('');
        }

        return path;
    };

    /**
     * Converts a Date().timezoneOffset value to a .NET date JSON string timezone
     * @param {int} offset - timezoneOffset() minutes of offset
     * @return {string}
     */
    module.private.timezoneOffsetToDotNetTimezone = function(offset) {
        var tzHours, tzHoursString, tzMins, tzMinsString, tzPlusMinus;

        tzHours = Math.abs(Math.floor(offset / 60));
        tzHoursString = tzHours.toString().length > 1 ? tzHours : '0' + tzHours;

        tzMins = offset % 60;
        tzMinsString = tzMins.toString().length > 1 ? tzMins : '0' + tzMins;

        tzPlusMinus = offset >= 0 ? '-' : '+';

        return [tzPlusMinus, tzHoursString, tzMinsString].join('');
    };

    /**
     * Converts a Date() object to a .NET date JSON string
     * @param {object} d - Date() object
     * @return {string}
     */
    module.dateToDotNetDate = function(d) {
        var ms, tz;

        if (typeof d !== 'object' && !d.getTime) {
            return false;
        }

        ms = d.getTime();
        tz = module.private.timezoneOffsetToDotNetTimezone(d.getTimezoneOffset());

        return ['/Date(', ms, tz, ')/'].join('');
    };

    /**
     * Construct leads search Mixpanel event.
     * @param {object} request Data sent to the webservice
     * @param {string} mixpanel 'type' event attritbute
     * @return {object}
     * @example
     * module.private.contructMixpanelData({}, 'lead-discovery');
     */
    module.constructMixpanelData = function(request, type, searchBy) {
        var mixpanelData;

        mixpanelData = $.extend({}, request);
        mixpanelData.type = type;
        mixpanelData.from = window.pageType;
        if (searchBy) {
            mixpanelData.searchBy = searchBy;
        }
        return mixpanelData;
    };

    /**
     * Debounce events
     * @param {function} fn the function to run
     * @param {number} delay the delay
     */
    module.debounce = function(fn, delay) {
        var timer = null;
        return function() {
            var context = this,
                args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function() {
                fn.apply(context, args);
            }, delay);
        };
    };

    /**
     * Throttle Events borrowed from {@link http://underscorejs.org/#throttle UnderscoreJs}
     * @param {function} fn the function you want to run
     * @param {number} delay func will run a maximum of once per this amount of time
     * @param {object} [options] options
     * @param {boolean} options.leading if the function is called and it is allowed to run, run it immediately
     * @param {boolean} optinos.trailing if the funciton is called before it is allowed to run, run it when the time is up
     */
    module.throttle = function(fn, delay, options) {
        var timeout, context, args, result;
        var previous = 0;
        if (!options) options = {};

        //when the timer expires
        var later = function() {
            //if we did not want the leading set previous to 0 so it is like its never run
            //otherwise set previous to now
            previous = options.leading === false ? 0 : new Date().getTime();

            //clear timer
            timeout = null;

            //run function
            result = fn.apply(context, args);

            //clear context and args
            if (!timeout) context = args = null;
        };

        var throttled = function() {
            var now, remaining;

            now = new Date().getTime();

            //if we do not want to run the function the first time immediately (e.g scroll event fires at page load)
            //make it look like it just ran
            if (!previous && options.leading === false) previous = now;

            //calculate how much time remains until we will allow the function to run again
            remaining = delay - (now - previous);

            context = this;
            args = arguments;

            //if the time remaining until the function is allowed to run again is at 0
            //clear the timer and run the function
            if (remaining <= 0 || remaining > delay) {
                //if the timeer exists clear it
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }

                //reset previous
                previous = now;

                //call the function
                result = fn.apply(context, args);

                //reset context and args
                if (!timeout) context = args = null;

                //if the function is not allowed to run right now because the last run was too recent
                //and the timer is not already running
                //and we want the trailing run
                //set the timer
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }

            return result;
        };

        throttled.cancel = function() {
            clearTimeout(timeout);
            previous = 0;
            timeout = context = args = null;
        };

        return throttled;
    };

    /**
     * Safely check/retrieve nested properties.
     * @param {object} obj The object to lookup
     * @param {string} props Nested properties separated by '.'
     * @return {string}
     */
    module.prop = function(obj, props) {
        return props.split('.').reduce(function(acc, p) {
            return typeof acc === 'undefined' || acc === null ? acc : acc[p];
        }, obj);
    };

    /**
     * Safely check/retrieve nested properties.
     * @param {string} props Nested RESAAS.Localization properties separated by '.'
     * @return {string}
     */
    module.resx = function(props) {
        return props.split('.').reduce(function(acc, p) {
            return typeof acc === 'undefined' || acc === null ? '' : acc[p] === undefined ? '' : acc[p];
        }, RESAAS.Localization);
    };

    /**
     * Escape and convert a string to regex
     * @param {string} str the string you want to match
     * @param {string} RegExp flags
     * @param {boolean} matchEndOfString If set to true, will add $ at end of regex to match end of string
     * @return {object} RegExp
     */
    module.stringToEscapedRegExp = function(str, flags, matchEndOfString) {
        var newStr;
        newStr = str.replace(/[\|\\\{\}\(\)\[\]\^\$\+\*\?\.]/g, '\\$&');
        if (matchEndOfString) {
            newStr = newStr + '$';
        }

        return new RegExp(newStr, flags);
    };

    /**
     * Calculate the navigation state
     * @return {object}
     */
    module.getNavigationState = function() {
        var stateObj, $nav;

        stateObj = {};
        $nav = $('.js-nav-container');

        if ($nav.length) {
            stateObj.isFixed = $nav.outerHeight() + $nav.offset().top < window.innerHeight;
        }
        if (Modernizr) {
            //based below on _variables.scss
            //$mediaquery-nav-compact-max: "only screen and (max-width: 60em)";
            stateObj.isCompact = Modernizr.mq('only screen and (max-width: 60em)');
        }

        return stateObj;
    };

    module.setCookie = function(cname, cvalue, exdays) {
        var c, d, e;

        if (exdays) {
            d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            e = ['expires=', d.toUTCString()].join('');
        }

        c = [cname, '=', cvalue, ';path=/'].join('');

        if (e) {
            c = [c, e].join(' ');
        }

        document.cookie = c;
    };

    module.getCookie = function(cname) {
        var name = cname + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };

    module.linkifyTextString = function(inputText) {
        if (!inputText || typeof inputText != 'string') return inputText;

        var replacedText = inputText;

        // URLs starting with http://, https://, or ftp://
        // source: https://gist.github.com/dperini/729294
        var replacePatternUrl = /(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[\/?#][^\s<]*)?(?![^<]*?<\/\w+?>)/gim; // jshint ignore:line
        var replacedText = replacedText.replace(
            replacePatternUrl,
            '<a href="$&" target="_blank" rel="noreferrer noopener">$&</a>'
        );

        // Change email addresses to mailto: links
        // source: http://emailregex.com/
        var replacePatternEmail = /(?!<a[^>]*?)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(?![^<]*?<\/a>)/gim;
        var replacedText = replacedText.replace(replacePatternEmail, '<a href="mailto:$&">$&</a>');

        // Change phone numbers to tel: links
        function cleanPhoneNumber(match) {
            var cleanPhone = match.replace(/\s|\(|\)|\.|-/g, '');
            var originalPhone = match;
            return '<a href="tel:' + cleanPhone + '">' + originalPhone + '</a>';
        }
        var replacePatternPhone = /(?!<a[^>]*?)(([+\d]{1,4})[^\S\n]?)?([().\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+(?![^<]*?<\/a>)/gim; // jshint ignore:line
        var replacedText = replacedText.replace(replacePatternPhone, cleanPhoneNumber);

        return replacedText;
    };

    module.linkifyKeywords = function(inputText) {
        var keyWordsData, replacedText, linkPattern1, linkPattern2;

        replacedText = inputText;
        keyWordsData = keywordsData.browse();

        keyWordsData.map(function keyWordsData(keyword) {
            linkPattern1 = new RegExp(module.stringFormat('<a.*?{0}.*?>.*?</a>', keyword.Name), 'im');
            linkPattern2 = new RegExp(module.stringFormat('<a.*?>.*?{0}.*?</a>', keyword.Name), 'im');
            if (replacedText.match(linkPattern1) == null && replacedText.match(linkPattern2) == null) {
                var replacePatternUrl = new RegExp(module.stringFormat('{0}', keyword.Name), 'im');
                replacedText = replacedText.replace(
                    replacePatternUrl,
                    '<a class="re-keyword-link" title="' +
                        RESAAS.Localization.Global.VISIBLE_ONLY_FOR_YOU +
                        '" data-mixpanel-name="Intent" data-mixpanel-type="keywords-links" data-mixpanel-keyword="' +
                        keyword.Name +
                        '" href="' +
                        keyword.Link +
                        '" target="_blank" rel="noopener">$&</a>'
                );
            }
        });

        return replacedText;
    };

    module.addClassToHtmlTag = function addClassToHtmlTag(classString) {
        document.getElementsByTagName('html')[0].className =
            document.getElementsByTagName('html')[0].className + ' ' + classString;
    };

    /**
     * Convert and array of hashes to a hash with the key equal to a key from each item
     * @param {array} array array to be converted
     * @param {string} key item key to use as new hash key
     * @return {object}
     */
    module.arrayToHash = function arrayToHash(array, key) {
        var i, l, hash;

        hash = {};

        if (array) {
            l = array.length;

            for (i = 0; i < l; i++) {
                hash[array[i][key]] = array[i];
            }
        }

        return hash;
    };

    /**
     * Determine whether an element is visbile within the viewport
     * @param {object} el DOM element to be checked
     * @param {object} [w] this allows dependency injection for testing as PhantomJs resizes its window to contain all elements
     * @return {boolean}
     */
    module.elementIsInViewport = function(el, w) {
        var rect;

        w = w || window;
        rect = el.getBoundingClientRect();

        return rect.top < w.innerHeight && rect.left < w.innerWidth && rect.bottom > 0 && rect.right > 0;
    };

    /**
     * Convert an inbox notification message to a BE response strcuture
     * @param {object} data the json data
     */
    module.convertToConversationObj = function(data) {
        var obj, convo, participant1, participant2, message;

        obj = {};
        obj.response = [];
        convo = {};
        participant1 = {};
        participant2 = {};
        message = {};

        convo.ConversationId = data.ConversationId;
        convo.IsSeen = false;

        convo.Participants = [];
        convo.Messages = [];
        convo.LastSentTimestamp = data.Timestamp;

        participant1.UserId = window.RESAAS.User2.UserId;
        participant1.ProfilePictureUrl = window.RESAAS.User2.UserProfileThumb;
        participant1.DisplayName = window.RESAAS.User2.FirstName + ' ' + window.RESAAS.User2.LastName;

        participant2.UserId = data.SenderUserId;
        participant2.ProfilePictureUrl = data.SenderProfileUrl;
        participant2.DisplayName = data.SenderFirstName + ' ' + data.SenderLastName;

        message.PlainTextMessage = data.Message;
        message.SenderUserId = data.SenderUserId;
        message.IsSeen = true;
        message.InstantMessageId = data.InstantMessageId;
        message.RecipientUserId = window.RESAAS.User2.UserId;
        message.ConversationId = null;
        message.Attachments = data.Attachments;

        convo.Participants.push(participant1);
        convo.Participants.push(participant2);

        convo.Messages.push(message);

        obj.response.push(convo);

        return obj;
    };

    /**
     * Simple wrapper for location.assign
     * @param {string} url the url
     */
    module.redirect = function(url) {
        window.location.assign(url);
    };

    /**
     * Simple wrapper for location.reload
     * @param {boolean} [forceGet=false] force reload of page from the server
     */
    module.reload = function(forceGet) {
        forceGet = typeof forceGet === 'boolean' ? forceGet : false;
        window.location.reload(forceGet);
    };

    module.unitDistanceConvertorTo = function(type, value) {
        switch (type) {
            case 'km':
                return parseInt(value, 10) * 1.609;
            case 'miles':
                return parseInt(value, 10) / 1.609;
            default:
                return 1;
        }
    };

    module.configureMoment = function configureMoment(moment) {
        // set locale
        var loc = module.prop(RESAAS, 'Environment.Language');
        if (loc === 'zh-Hans') {
            loc = 'zh-cn';
        } else if (loc === 'zh-Hant') {
            loc = 'zh-tw';
        } else if (loc === 'fil-PH') {
            loc = 'tl-ph';
        }

        moment.locale(loc);

        // Set new thresholds
        moment.relativeTimeThreshold('s', 60);
        moment.relativeTimeThreshold('ss', 0);
        moment.relativeTimeThreshold('m', 60);
        moment.relativeTimeThreshold('h', 24);
        moment.relativeTimeThreshold('d', 31);
        moment.relativeTimeThreshold('M', 12);
    };

    module.attachedLinkType = function attachedLinkType(str) {
        var env,
            leadsDetailsLink,
            listingDetailsLink,
            userProfileLink,
            reblastDetailsLink,
            internalLink,
            linkTypes,
            hasInternalNumber,
            hasLeadsDetailsLink,
            hasListingDetailsLink,
            hasUserProfileLink,
            hasReblastDetailsLink,
            hasInternalLink,
            hasExternalLink,
            i;

        env = window.RESAAS.Environment.ApplicationUrl.replace('https://', '');
        leadsDetailsLink = new RegExp(
            module.stringFormat('{0}/referral/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}', env),
            'gim'
        );
        listingDetailsLink = new RegExp(module.stringFormat('{0}/(.+)/details/', env), 'gim');
        userProfileLink = new RegExp(module.stringFormat('{0}/(.+)/profile', env), 'gim');
        reblastDetailsLink = new RegExp(
            module.stringFormat('{0}/reblast/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}', env),
            'gim'
        );
        internalLink = new RegExp(env, 'gim');

        hasLeadsDetailsLink = str.match(leadsDetailsLink);
        hasListingDetailsLink = str.match(listingDetailsLink);
        hasUserProfileLink = str.match(userProfileLink);
        hasReblastDetailsLink = str.match(reblastDetailsLink);
        hasInternalLink = str.match(internalLink);
        hasExternalLink = str.match(/https?:\/\/(?!resaas)([\w]*\.)(?!resaas)\S*/gim);

        linkTypes = [];
        hasInternalNumber = 0;

        if (hasLeadsDetailsLink) {
            hasLeadsDetailsLink.forEach(function hasLeadsDetailsLinkForEach() {
                linkTypes.push('Leads Details Link');
                hasInternalNumber += 1;
            });
        }

        if (hasListingDetailsLink) {
            hasListingDetailsLink.forEach(function hasListingDetailsLinkForEach() {
                linkTypes.push('Listing Details Link');
                hasInternalNumber += 1;
            });
        }

        if (hasUserProfileLink) {
            hasUserProfileLink.forEach(function hasUserProfileLinkForEach() {
                linkTypes.push('Profile Link');
                hasInternalNumber += 1;
            });
        }

        if (hasReblastDetailsLink) {
            hasReblastDetailsLink.forEach(function hasReblastDetailsLinkForEach() {
                linkTypes.push('Reblast Link');
                hasInternalNumber += 1;
            });
        }

        if (hasInternalLink && hasInternalLink.length > hasInternalNumber) {
            for (i = 0; i < hasInternalLink.length - hasInternalNumber; i++) {
                linkTypes.push('Internal Link');
            }
        }

        if (hasExternalLink) {
            hasExternalLink.forEach(function hasExternalLinkForEach() {
                linkTypes.push('External Link');
            });
        }

        if (linkTypes.length > 0) {
            return linkTypes;
        }

        return null;
    };

    /*
     * convert set dates timestamp to T23:59:59 Local time, then convert to UTC Timestamp YYYY-MM-DDThh:mm:ss
     * @param localDate YYYY-MM-DD
     * @return UTC YYYY-MM-DDThh:mm:ss
     */
    module.convertLocalToUTCDate = function convertLocalToUTCDate(localDate) {
        var date;
        if (!localDate) {
            return localDate;
        }
        date = new Date(module.stringFormat('{0}T23:59:59', localDate));
        return date.toISOString().substr(0, 19);
    };

    /*
     * convert set utc timestamp to YYYY-MM-DDThh:mm:ss to Local date
     * @param UTC Date YYYY-MM-DDThh:mm:ss
     * @return Local Date YYYY-MM-DD
     */
    module.convertUTCToLocalDate = function convertUTCToLocalDate(moment, utcDate) {
        var date;
        if (!utcDate) {
            return utcDate;
        }
        date = new Date(module.stringFormat('{0}Z', utcDate));
        return moment(date).format('YYYY-MM-DD');
    };

    /**
     * initializes a jquery date picker, based on input name, and maxdate
     * @param fieldName is the name of field
     * @param maxDate is optional, sets max date selectable by datepicket
     */
    module.setupDatePicker = function setupDatePicker(fieldName, maxDate) {
        var dateField, config;
        dateField = $(module.stringFormat('[name="{0}"]', fieldName));
        config = {
            timepicker: false,
            format: 'Y-m-d',
            validateOnBlur: false,
            minDate: '+1970/01/02',
            scrollInput: false
        };
        if (maxDate) {
            config.maxDate = maxDate;
        }
        if (dateField.length) {
            dateField.datetimepicker(config);
        }
    };

    module.showHiddenPhoneNumber = function showHiddenPhoneNumber(buttonSelector, mobileSelector, officeSelector) {
        var button, mobile, office;
        button = document.querySelector(buttonSelector);
        mobile = document.querySelector(mobileSelector);
        office = document.querySelector(officeSelector);

        if (button) {
            button.setAttribute('data-mixpanel-name', 'Intent');
            button.setAttribute('data-mixpanel-type', 'reveal phone');
            button.setAttribute('data-mixpanel-context', 'sidebar');

            button.onclick = function onclick() {
                button.classList.add('hidden');
                if (mobile) {
                    mobile.classList.remove('hidden');
                }
                if (office) {
                    office.classList.remove('hidden');
                }
            };
        }
    };
    /**
     * Range tool tip.
     * @param {object} e The event.
     * @param {radiusGlobal} e The MAX radius.
     */
    module.rangeToolTip = function rangeToolTip(e) {
        var rangeWidth, newPoint, $percentage, $dRange, $magic, thumbVal, min, max, leftValue, px;

        if (e) {
            e.preventDefault();
        }

        $dRange = $('#range-input');
        $magic = $('#range-input').data('magic');
        $percentage = $('#range-input').data('percentage');
        rangeWidth = $dRange.width();

        if (rangeWidth !== null) {
            newPoint = $dRange.val() / $dRange.attr('max');

            min = $dRange.attr('min') ? $dRange.attr('min') : 0;
            max = $dRange.attr('max') ? $dRange.attr('max') : 100;
            newPoint = Number((($dRange.val() - min) * 100) / (max - min));
            px = 5 - (newPoint * $percentage + $magic);

            leftValue = 'calc(' + newPoint + '% + ' + px + 'px)';

            thumbVal = $dRange.val();

            $dRange
                .next('output')
                .addClass('re-output-range')
                .css({ left: leftValue })
                .text(thumbVal);
        }
    };

    module.formatCurrency = function formatCurrency(v) {
        var formatted, number;

        if (Number(v) > Number.MAX_SAFE_INTEGER) {
            return v;
        }

        number = Number(v);

        if (Number.isInteger(number)) {
            formatted = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        } else {
            formatted = number.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        }

        if (formatted === '0' || formatted === 'NaN') {
            return v;
        }

        return formatted;
    };

    module.getDateYMD = function(d) {
        var day = ['0', d.getDate()].join('');
        var m = ['0', d.getMonth() + 1].join('');
        return [d.getFullYear(), m.substring(m.length - 2), day.substring(day.length - 2)].join('-');
    };

    // date formats can range from 2023-09-25 to 2023-09-25T18:34:01.727
    module.daysAgo = function daysAgoCreated(now, pastDate) {
        var c, d, m, n, x;

        if (typeof pastDate !== 'string') {
            return null;
        }

        if (typeof now !== 'string') {
            return null;
        }

        c = pastDate.substr(0, 10);
        d = new Date(c).getTime();
        m = now.substr(0, 10);
        n = new Date(m).getTime();

        x = Math.floor((n - d) / (24 * 60 * 60 * 1000));

        if (typeof x === 'number' && !isNaN(x) && x >= 0) {
            return x;
        }

        return null;
    };

    return module;
});
