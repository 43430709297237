﻿define(['jquery', 'pubsub', 'ajaxUtilitiesHelpers'], function ($, pubsub, ajaxUtilitiesHelpers) {
	'use strict';

	var module;

	module = {};

	//public methods
	//-------------------------------------------

	module.ajax = function (ajaxOptions) {
		ajaxOptions = ajaxUtilitiesHelpers.cleanOptions(ajaxOptions);
		return Q($.ajax(ajaxOptions));
	};

	module.publish = function (event, ajaxOptions) {
		var responseHandler, rawData;

		rawData = (ajaxOptions.data) ? ajaxOptions.data : null;
		ajaxOptions = ajaxUtilitiesHelpers.cleanOptions(ajaxOptions);

		responseHandler = (function (rawData) {
			return function (response) {
				pubsub.publish(event, { 'request': rawData, 'response': response });
			}
		}(rawData));

		return Q($.ajax(ajaxOptions)).then(responseHandler, responseHandler).done();
	};

	module.publish2 = function (e, serviceObject, ajaxOptions) {
		var c, responseHandler;

		e = ajaxUtilitiesHelpers.convertEventNameToResponseName(e);
		c = ajaxUtilitiesHelpers.createAjaxOptions(ajaxOptions, serviceObject.request);
		c = ajaxUtilitiesHelpers.cleanOptions(c);

		responseHandler = (function (serviceObject) {
			return function (response) {
				var publishObject, cleanData;

				cleanData = $.extend({}, serviceObject);

				publishObject = cleanData;
				publishObject.request = serviceObject.request;
				publishObject.response = response;

				pubsub.publish(e, publishObject);
			}
		}(serviceObject));

		return Q($.ajax(c)).then(responseHandler, responseHandler).done();
	};

	return module;
});