import React from 'react';
import PropTypes from 'prop-types';

const Button = props => (
    <button
        className={`${props.className} ${props.loading ? 'submitting' : ''}`}
        type={props.type}
        onClick={props.onClick ? props.onClick : null}
        title={props.title}
        data-testid={props.dataTestid}
        data-mixpanel-name={props.mixpanelName}
        data-mixpanel-type={props.mixpanelType}
        data-mixpanel-context={props.mixpanelContext}
    >
        {props.text}
    </button>
);

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    dataTestid: PropTypes.string,
    mixpanelName: PropTypes.string,
    mixpanelType: PropTypes.string,
    mixpanelContext: PropTypes.string
};

export default Button;
