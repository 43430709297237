﻿define(['jquery', 'pubsub', 'generalUtilities', 'ajaxUtility',
		'mixpanelControl', 'logglyController', 'qtipLib'],

	function ($, pubsub, generalUtilities, ajaxUtility) {
		'use strict';

        var module, elements, values, qTipConfig, languageMapping, moreLanguages, contentText;

		module = {};
		module.private = {};
		elements = {};
        values = {};

        languageMapping = {
            'id-ID': 'id',      // Bahasa Indonesia 
            'cs-CZ': 'cs',      // Čeština
            'de-DE': 'de',      // Deutsch
            'en-US': 'en',      // English
            'es-ES': 'es',      // Español
            'fr-FR': 'fr',      // Français
            'it-IT': 'it',      // Italiano
            'hu-HU': 'hu',      // Magyar 
            'pl-PL': 'pl',      // Polski
            'pt-PT': 'pt',      // Português
            'sk-SK': 'sk',      // Slovenčina
            'fi-FI': 'fi',      // Suomi
            'fil-PH': 'tl',     // Tagalog
            'tr-TR': 'tr',      // Türkçe
            'el-GR': 'el',      // Eλληνικά
            'th-TH': 'th',      // ภาษาไทย
            'zh-Hans': 'zh-CN', // 简体中文
            'zh-Hant': 'zh-TW', // 繁體中文
            'ko-KR': 'ko',      // 한국어
            'ja-JP': 'ja',      // 日本語
            'hi-IN': 'hi',      // हिन्दी (Beta)
            'gu-IN': 'gu'       // ગુજરાતી (Beta)
        };

        moreLanguages = [
            { code: "ab", name: "Abkhaz" },
            { code: "ace", name: "Acehnese" },
            { code: "ach", name: "Acholi" },
            { code: "aa", name: "Afar" },
            { code: "af", name: "Afrikaans" },
            { code: "sq", name: "Albanian" },
            { code: "alz", name: "Alur" },
            { code: "am", name: "Amharic" },
            { code: "ar", name: "Arabic" },
            { code: "hy", name: "Armenian" },
            { code: "as", name: "Assamese" },
            { code: "av", name: "Avar" },
            { code: "awa", name: "Awadhi" },
            { code: "ay", name: "Aymara" },
            { code: "az", name: "Azerbaijani" },
            { code: "ban", name: "Balinese" },
            { code: "bal", name: "Baluchi" },
            { code: "bm", name: "Bambara" },
            { code: "bci", name: "Baoulé" },
            { code: "ba", name: "Bashkir" },
            { code: "eu", name: "Basque" },
            { code: "btx", name: "Batak Karo" },
            { code: "bts", name: "Batak Simalungun" },
            { code: "bbc", name: "Batak Toba" },
            { code: "be", name: "Belarusian" },
            { code: "bem", name: "Bemba" },
            { code: "bn", name: "Bengali" },
            { code: "bew", name: "Betawi" },
            { code: "bho", name: "Bhojpuri" },
            { code: "bik", name: "Bikol" },
            { code: "bs", name: "Bosnian" },
            { code: "br", name: "Breton" },
            { code: "bg", name: "Bulgarian" },
            { code: "bua", name: "Buryat" },
            { code: "yue", name: "Cantonese" },
            { code: "ca", name: "Catalan" },
            { code: "ceb", name: "Cebuano" },
            { code: "ch", name: "Chamorro" },
            { code: "ce", name: "Chechen" },
            { code: "ny", name: "Chichewa" },
            { code: "chk", name: "Chuukese" },
            { code: "cv", name: "Chuvash" },
            { code: "co", name: "Corsican" },
            { code: "crh", name: "Crimean Tatar" },
            { code: "hr", name: "Croatian" },
            { code: "cs", name: "Czech" },
            { code: "da", name: "Danish" },
            { code: "fa-AF", name: "Dari" },
            { code: "dv", name: "Dhivehi" },
            { code: "din", name: "Dinka" },
            { code: "doi", name: "Dogri" },
            { code: "dov", name: "Dombe" },
            { code: "nl", name: "Dutch" },
            { code: "dyu", name: "Dyula" },
            { code: "dz", name: "Dzongkha" },
            { code: "eo", name: "Esperanto" },
            { code: "et", name: "Estonian" },
            { code: "ee", name: "Ewe" },
            { code: "fo", name: "Faroese" },
            { code: "fj", name: "Fijian" },
            { code: "tl", name: "Filipino" },
            { code: "fi", name: "Finnish" },
            { code: "fon", name: "Fon" },
            { code: "fy", name: "Frisian" },
            { code: "fur", name: "Friulian" },
            { code: "ff", name: "Fulani" },
            { code: "gaa", name: "Ga" },
            { code: "gl", name: "Galician" },
            { code: "ka", name: "Georgian" },
            { code: "de", name: "German" },
            { code: "el", name: "Greek" },
            { code: "gn", name: "Guarani" },
            { code: "gu", name: "Gujarati" },
            { code: "ht", name: "Haitian Creole" },
            { code: "cnh", name: "Hakha Chin" },
            { code: "ha", name: "Hausa" },
            { code: "haw", name: "Hawaiian" },
            { code: "iw", name: "Hebrew" },
            { code: "hil", name: "Hiligaynon" },
            { code: "hmn", name: "Hmong" },
            { code: "hu", name: "Hungarian" },
            { code: "hrx", name: "Hunsrik" },
            { code: "iba", name: "Iban" },
            { code: "is", name: "Icelandic" },
            { code: "ig", name: "Igbo" },
            { code: "ilo", name: "Ilocano" },
            { code: "id", name: "Indonesian" },
            { code: "ga", name: "Irish" },
            { code: "jam", name: "Jamaican Patois" },
            { code: "jw", name: "Javanese" },
            { code: "kac", name: "Jingpo" },
            { code: "kl", name: "Kalaallisut" },
            { code: "kn", name: "Kannada" },
            { code: "kr", name: "Kanuri" },
            { code: "pam", name: "Kapampangan" },
            { code: "kk", name: "Kazakh" },
            { code: "kha", name: "Khasi" },
            { code: "km", name: "Khmer" },
            { code: "cgg", name: "Kiga" },
            { code: "kg", name: "Kikongo" },
            { code: "rw", name: "Kinyarwanda" },
            { code: "ktu", name: "Kituba" },
            { code: "trp", name: "Kokborok" },
            { code: "kv", name: "Komi" },
            { code: "gom", name: "Konkani" },
            { code: "kri", name: "Krio" },
            { code: "ku", name: "Kurdish (Kurmanji)" },
            { code: "ckb", name: "Kurdish (Sorani)" },
            { code: "ky", name: "Kyrgyz" },
            { code: "lo", name: "Lao" },
            { code: "ltg", name: "Latgalian" },
            { code: "la", name: "Latin" },
            { code: "lv", name: "Latvian" },
            { code: "lij", name: "Ligurian" },
            { code: "li", name: "Limburgish" },
            { code: "ln", name: "Lingala" },
            { code: "lt", name: "Lithuanian" },
            { code: "lmo", name: "Lombard" },
            { code: "lg", name: "Luganda" },
            { code: "luo", name: "Luo" },
            { code: "lb", name: "Luxembourgish" },
            { code: "mk", name: "Macedonian" },
            { code: "mad", name: "Madurese" },
            { code: "mai", name: "Maithili" },
            { code: "mak", name: "Makassar" },
            { code: "mg", name: "Malagasy" },
            { code: "ms", name: "Malay" },
            { code: "ms-Arab", name: "Malay (Jawi)" },
            { code: "ml", name: "Malayalam" },
            { code: "mt", name: "Maltese" },
            { code: "gv", name: "Manx" },
            { code: "mi", name: "Maori" },
            { code: "mr", name: "Marathi" },
            { code: "mwr", name: "Marwari" },
            { code: "mh", name: "Marshallese" },
            { code: "mas", name: "Masai" },
            { code: "men", name: "Mende" },
            { code: "lus", name: "Mizo" },
            { code: "mn", name: "Mongolian" },
            { code: "mos", name: "Mossi" },
            { code: "my", name: "Myanmar (Burmese)" },
            { code: "na", name: "Nauruan" },
            { code: "nv", name: "Navajo" },
            { code: "ng", name: "Ndonga" },
            { code: "ne", name: "Nepali" },
            { code: "pcm", name: "Nigerian Pidgin" },
            { code: "nso", name: "Northern Sotho" },
            { code: "no", name: "Norwegian" },
            { code: "nov", name: "Novial" },
            { code: "nyo", name: "Nyoro" },
            { code: "nyn", name: "Nyankole" },
            { code: "oc", name: "Occitan" },
            { code: "or", name: "Odia (Oriya)" },
            { code: "om", name: "Oromo" },
            { code: "os", name: "Ossetian" },
            { code: "pi", name: "Pali" },
            { code: "pau", name: "Palauan" },
            { code: "ps", name: "Pashto" },
            { code: "fa", name: "Persian" },
            { code: "pl", name: "Polish" },
            { code: "pa", name: "Punjabi" },
            { code: "ro", name: "Romanian" },
            { code: "rm", name: "Romansh" },
            { code: "ru", name: "Russian" },
            { code: "sma", name: "Sami (Southern)" },
            { code: "sg", name: "Sango" },
            { code: "sa", name: "Sanskrit" },
            { code: "sat", name: "Santali" },
            { code: "sc", name: "Sardinian" },
            { code: "gd", name: "Scottish Gaelic" },
            { code: "sly", name: "Selayar" },
            { code: "sr", name: "Serbian" },
            { code: "sh", name: "Serbo-Croatian" },
            { code: "st", name: "Sesotho" },
            { code: "tn", name: "Setswana" },
            { code: "sn", name: "Shona" },
            { code: "sd", name: "Sindhi" },
            { code: "si", name: "Sinhala" },
            { code: "sk", name: "Slovak" },
            { code: "sl", name: "Slovenian" },
            { code: "so", name: "Somali" },
            { code: "su", name: "Sundanese" },
            { code: "sw", name: "Swahili" },
            { code: "sv", name: "Swedish" },
            { code: "tg", name: "Tajik" },
            { code: "ta", name: "Tamil" },
            { code: "tt", name: "Tatar" },
            { code: "te", name: "Telugu" },
            { code: "th", name: "Thai" },
            { code: "bo", name: "Tibetan" },
            { code: "tig", name: "Tigre" },
            { code: "ti", name: "Tigrinya" },
            { code: "tpi", name: "Tok Pisin" },
            { code: "to", name: "Tongan" },
            { code: "tk", name: "Turkmen" },
            { code: "tvl", name: "Tuvaluan" },
            { code: "ug", name: "Uyghur" },
            { code: "uk", name: "Ukrainian" },
            { code: "ur", name: "Urdu" },
            { code: "uz", name: "Uzbek" },
            { code: "vi", name: "Vietnamese" },
            { code: "cy", name: "Welsh" },
            { code: "wo", name: "Wolof" },
            { code: "xh", name: "Xhosa" },
            { code: "yi", name: "Yiddish" },
            { code: "yo", name: "Yoruba" },
            { code: "zu", name: "Zulu" }
        ];

		elements.html = $('html');

		values.languageLinkSelector = '.js-select-language';
        values.selectLanguageMoreLinkSelector = '.js-select-language-more-link';
        values.moreLanguage = '.js-more-language';
        values.moreLanguageModal = '.js-more-language-modal';

        module.private.generateMoreLanguage = function () {
            var list = "";
            for (var i = 0; i < moreLanguages.length; i++) {
                if (i % 10 === 0) list += '<ul>';
                list += '<li><a class="js-select-language" data-language="' + moreLanguages[i].code + '">' + moreLanguages[i].name + '</a></li>';
                if (i % 10 === 9 || i === moreLanguages.length - 1) list += '</ul>';
            }
            return list;
        };


        contentText = '<div class="re-footer-language-modal notranslate">' + module.private.generateMoreLanguage() + '</div>';

        qTipConfig = {
            moreLanguageToolTip: {
                selector: '.js-more-language',
                text: contentText,
            }
        };

        module.private.bindqTip = function bindqTip() {

            elements.body = $('body');
            $.each(qTipConfig, function qTipConfigFunc(key, value) {
                var tooltipWidth = $(window).width() < 768 ? '80%' : 430; 

                $(value.selector).qtip({
                    content: {
                        text: value.text
                    },
                    position: {
                        my: 'bottom center',
                        at: 'top center',
                        target: $(value.selector),
                        viewport: $(window),
                        adjust: {
                            method: 'shift shift'
                        }
                    },
                    hide: {
                        event: 'click'
                    },
                    show: {
                        event: 'click',
                        solo: true
                    },
                    style: {
                        def: false,
                        classes: 're-qtip-white',
                        width: tooltipWidth,
                        tip: {
                            corner: 'bottom center',
                            width: 20,
                            height: 10,
                            border: 1
                        }
                    },
                    events: {
                        render: function eventsRender(event, api) {

                            $(api.elements.tooltip).css('max-width', 'none');
                            elements.body.on('click', function eventsRenderBodyClick() {
                                $(api.elements.tooltip).hide();
                            });
                        }
                    }
                });
            });
        };

		module.private.validateLanguageCode = function (l) {
			var languageRegex, result;

			languageRegex = /^[a-z]{2,3}(-[A-Z]{1}[a-z|A-Z]{1,3})?$/;
			result = languageRegex.test(l);

			if (!result) {
				pubsub.publish('loggly.error', { 'message': 'set language called with invalid langUage code: "' + l + '" on page: "' + window.location.pathname + '"'});
			}

			return result;
		};

		module.private.setRemoteData = function () {
			return ajaxUtility.ajax({
				'type': 'PUT',
				'url': '/Services/Api/Preferences',
				'contentType': 'application/json',
				'dataType': 'json',
				'data': JSON.stringify({ 
					'Preferences': [
						{
							'Value': values.language,
							'PreferenceType': 2
						}
					]
				})
			}).then(
				function (d) {
					if (!d.Success) {
						pubsub.publish('loggly.error', { 'message': 'Failed to set language preference (webservice returned false): ' + values.language });
					}
				},
				function () {
					pubsub.publish('loggly.error', { 'message': 'Failed to set language preference (ajax error): ' + values.language });
				}
			);
		};

		module.private.setMixPanel = function () {
			var d = Q.defer();

			mixpanel.people.set({ $language: values.language }, function () {
				setTimeout(function () {
					d.resolve();
				}, 500)
			});
            RESAAS.MixPanel.trackEvent('Intent', { type: 'switch language', context: values.language });
			return d.promise;
		};

        module.private.translateTo = function translateTo(languageCode) {

            // Map the languageCode to the correct value for Google Translate
            var googleTranslateCode = languageMapping[languageCode] || languageCode;

            var translate_element = document.querySelector('.goog-te-combo');
            if (translate_element) {
                translate_element.value = googleTranslateCode;
                translate_element.dispatchEvent(new Event('change'));
            }
        };

        module.private.processAndRedirect = function (e) {
            if (RESAAS && RESAAS.User2 && RESAAS.User2.IsProfessional && RESAAS.User2.UserId) {
                module.private.setRemoteData()
                    .then(module.private.setMixPanel)
                    .then(module.private.redirect())
                    .done();
            } else {
                module.private.redirect();
            }
        };

        module.private.redirect = function (e) {
           window.location.reload();
        };

        module.private.handleLanguageClick = function (e) {
            var $this, date;

            e.preventDefault();
            $this = $(e.currentTarget);
            values.language = $this.attr('data-language');

            if (!module.private.validateLanguageCode(values.language)) {
                return false;
            }

            date = new Date();
            date.setTime(date.getTime() + (15 * 60000));
            document.cookie = "language=" + values.language + "; expires=" + date.toUTCString() + "; path=" + '/';

            if (values.language === 'en-US') {
                // Clean the Google Translate cookies
                document.cookie = 'googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                document.cookie = 'googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.resaas.com;';

                // Process and redirect after cookie deletion
                module.private.processAndRedirect(e);
            } else {
                // Ensure remote data and redirection happen for other languages
                module.private.processAndRedirect(e);
            }
        };

		//init
        module.private.init = function init() {
            var envLanguage, userLanguage;
            
            userLanguage = generalUtilities.prop(window, 'RESAAS.User2.Language');
            envLanguage = generalUtilities.prop(window, 'RESAAS.Environment.Language');

            // for anonymous user
            if (!userLanguage && envLanguage && envLanguage !== 'en-US') {
                module.private.translateTo(envLanguage);
            // for logged in user
            } else if (userLanguage && userLanguage !== 'en-US') {
                module.private.translateTo(userLanguage);
            } else {
                return false;
            } 
            
        };

        elements.html.on('click', values.languageLinkSelector, module.private.handleLanguageClick);

        // Ensure Google Translate elemnts are loaded
        var observer = new MutationObserver(function (mutationsList) {
            for (var i = 0; i < mutationsList.length; i++) {
                var mutation = mutationsList[i];
                if (mutation.type === 'childList') {
                    var googleTranslateSelect = document.querySelector('.goog-te-combo');
                    if (googleTranslateSelect) {
                        if (googleTranslateSelect.options.length > 0) {
                            module.private.bindqTip();
                            module.private.init();
                            observer.disconnect();
                            break;
                        }
                    }
                }
            }
        });


        observer.observe(document.body, { childList: true, subtree: true });


		return module;

	}
);
