import _path from 'ramda/src/path';
import LogglyLogger from '../API/Loggly';
import { LOGGLY_LEVEL_ERROR, LOGGLY_LEVEL_WARN } from '../Constants';

const formatPhone = phone => {
    // if user has their own formatting it will be preserved
    // only 10 digit numbers or country code 1 and 10 digits will be formatted

    if (typeof phone === 'string') {
        // north america no country code (6048860610)
        if (/^\d{10}$/.test(phone)) {
            return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }

        // north america with country code (16048860610 / +16048860610)
        if (/^\+*1\d{10}$/.test(phone)) {
            return phone.replace(/(?:\+*)(1)(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
        }
    }

    return phone;
};

const formatPhoneHref = phone => {
    if (typeof phone === 'string') {
        return `tel:${phone.replace(/[^\d+]/g, '')}`;
    }
    return phone;
};

const queryStringToObject = queryString => {
    let qs = queryString || window.location.search;
    const qso = {};

    qs = qs.substr(1);
    // Transforms a query string into a JSON object. replace '+' with ' '.
    qs = qs.replace(/\+/g, ' ');
    const qsa = qs.split('&');
    qsa.forEach(item => {
        if (item) {
            const ia = item.split('=');
            qso[ia[0]] = ia[1];
        }
    });

    return qso;
};

const queryStringToObjectPlus = queryString => {
    /* Transforms a query string into a JSON object. does not replace '+' with ' '.
    for example a google map we need to keep +
    e.g. : north+vancouver
    */
    var qs, qsa, qso, ia, x, l;

    qs = queryString || window.location.search;
    qso = {};
    qs = qs.substr(1);
    qsa = qs.split('&');

    for (x = 0, l = qsa.length; x < l; x++) {
        if (qsa[x]) {
            ia = qsa[x].split('=');
            qso[ia[0]] = ia[1];
        }
    }

    return qso;
};

const objectToQueryString = object => {
    let string = '';

    Object.keys(object).forEach(key => {
        string += [key, '=', object[key], '&'].join('');
    });

    string = string.slice(0, string.lastIndexOf('&'));
    return string;
};

const addParamsToUrlQueryString = (url, object) => {
    let oldQueryString;
    let pureUrl;
    let oldObject;
    let newObject;
    let newQueryString;

    const queryStringIndex = url.indexOf('?');

    if (queryStringIndex >= 0) {
        oldQueryString = url.slice(queryStringIndex);
        pureUrl = url.slice(0, queryStringIndex);
        oldObject = queryStringToObjectPlus(oldQueryString);
        newObject = Object.assign(oldObject, object);
        newQueryString = objectToQueryString(newObject);
    } else {
        pureUrl = url;
        newQueryString = objectToQueryString(object);
    }

    return `${pureUrl}?${newQueryString}`;
};

const linkifyTextString = inputText => {
    if (!inputText || typeof inputText !== 'string') return inputText;

    // source: https://gist.github.com/dperini/729294
    const replacePatternUrl = /(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[\/?#][^\s<]*)?(?![^<]*?<\/\w+?>)/gim; // jshint ignore:line
    // source: http://emailregex.com/
    const replacePatternEmail = /(?!<a[^>]*?)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(?![^<]*?<\/a>)/gim;
    const replacePatternPhone = /(?!<a[^>]*?)(([+\d]{1,4})[^\S\n]?)?([().\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+[^\S\n]?([.\-\d])+(?![^<]*?<\/a>)/gim;
    let replacedText = inputText;

    // URLs starting with http://, https://, or ftp://
    replacedText = replacedText.replace(
        replacePatternUrl,
        '<a href="$&" target="_blank" rel="noreferrer noopener">$&</a>'
    );

    // Change email addresses to mailto: links
    replacedText = replacedText.replace(replacePatternEmail, '<a href="mailto:$&">$&</a>');

    // Change phone numbers to tel: links
    function cleanPhoneNumber(match) {
        var cleanPhone = match.replace(/\s|\(|\)|\.|-/g, '');
        var originalPhone = match;
        return `<a href="tel:${cleanPhone}">${originalPhone}</a>`;
    }

    replacedText = replacedText.replace(replacePatternPhone, cleanPhoneNumber);

    return replacedText;
};

const isInViewport = elem => {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const addWindowLock = () => {
    // will warn users when navigation out of current page
    // to prevent navigation within current spa use in conjuction with "https://reacttraining.com/react-router/core/api/Prompt"
    if (!window.RESAAS.beforeunloadLockApplied) {
        window.addEventListener('beforeunload', event => {
            if (window.RESAAS.beforeunloadLock) {
                event.preventDefault();
                event.returnValue = '';
            }
        });
    }
    window.RESAAS.beforeunloadLockApplied = true;
    window.RESAAS.beforeunloadLock = true;
};

const removeWindowLock = () => {
    window.RESAAS.beforeunloadLock = false;
};

const validateFields = (fields, validationStrings) => {
    const newState = {};
    let stillInvalid = false;

    for (let i = 0; i < fields.length; ++i) {
        const el = document.getElementById(fields[i]);
        if (el) {
            const isValid = el.checkValidity();
            const isTextareaRequiredInvalid =
                el.nodeName.toLowerCase() === 'textarea' && el.hasAttribute('required') && !el.value.trim().length;

            if (!isValid || isTextareaRequiredInvalid) {
                const keys = Object.keys(validationStrings[fields[i]]);
                const customValidity = {};
                if (isTextareaRequiredInvalid) {
                    customValidity.valueMissing = true;
                }
                for (let it = 0; it < keys.length; ++it) {
                    const key = keys[it];
                    if (el.validity[key] || customValidity[key]) {
                        newState[fields[i]] = validationStrings[fields[i]][key];
                        stillInvalid = true;
                    }
                }
            } else {
                newState[fields[i]] = '';
            }
        }
    }

    newState.allFieldsValidated = !stillInvalid;

    return newState;
};

const riskyFiles = name => {
    if (!name) return false;
    const filExt = name.split('.').pop();
    return /^(ADE|ADP|APP|ASA|ASP|BAS|BAT|CER|CHM|CMD|COM|CPL|CRT|CSH|DLL|EXE|FXP|HLP|HTA|HTM|HTML|HTR|INF|INS|ISP|ITS|JS|JSE|KSH|LNK|MAD|MAF|MAG|MAM|MAQ|MAR|MAS|MAT|MAU|MAV|MAW|MDA|MDB|MDE|MDT|MDW|MDZ|MHT|MHTM|MHTML|MSC|MSI|MSP|MST|OCX|OPS|PCD|PIF|PRF|PRG|REG|SCF|SCR|SCT|SHB|SHS|TMP|URL|VB|VBE|VBS|VBX|VSMACROS|VSS|VST|VSW|WS|WSC|WSF|WSH|XSL)$/gi.test(
        filExt
    );
};

const getLabelFromValue = (arr, value) => {
    for (let i = 0; i < arr.length; ++i) {
        if (arr[i].value === value) {
            return arr[i].label;
        }
    }
    return '';
};

const getValueFromLabel = (arr, label) => {
    for (let i = 0; i < arr.length; ++i) {
        if (arr[i].label === label) {
            return arr[i].value;
        }
    }
    return '';
};

const hasValue = (arr, value) => {
    for (let i = 0; i < arr.length; ++i) {
        if (arr[i].value === value) {
            return arr[i].value;
        }
    }
    return '';
};

const appendPendingRequest = (pendingRequests, request) => {
    pendingRequests.push(request);
};

const cleanupRequests = pendingRequests => {
    for (let it = 0; it < pendingRequests.length; ++it) {
        if (pendingRequests[it].hasOwnProperty('cancel')) {
            pendingRequests[it].cancel();
        }
    }
    pendingRequests.splice(0, pendingRequests.length);
};

const logWebRequestFailure = (error, message, warn) => {
    const status = _path(['response', 'status'], error);
    // ignore 499 = Connection Closed, as this is triggered by client
    if (status && status === 499) {
        return;
    }
    LogglyLogger.add(warn ? LOGGLY_LEVEL_WARN : LOGGLY_LEVEL_ERROR , `WebRequestFailure: ${message}`, error);
};

const handleTableRowClick = (e, url) => {
    if (e.type === 'click') {
        window.location.assign(url);
    }
};

const renderCurrency = (price, currencyCode) => {
    const language = _path(['RESAAS', 'Environment', 'Language'], window);
    if (!currencyCode) {
        return price;
    }

    return `${new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(
        price
    )} ${currencyCode}`;
};

const formatNumber = v => {
    if (Number(v) > Number.MAX_SAFE_INTEGER) {
        return v;
    }

    const formatted = Number(v).toLocaleString(undefined, { maximumFractionDigits: 2 });

    if (formatted === '0' || formatted === 'NaN') {
        return v;
    }

    return formatted;
};

const formatCurrency = v => {
    let formatted;
    if (Number(v) > Number.MAX_SAFE_INTEGER) {
        return v;
    }

    const number = Number(v);

    if (Number.isInteger(number)) {
        formatted = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
    } else {
        formatted = number.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    if (formatted === '0' || formatted === 'NaN') {
        return v;
    }

    return formatted;
};

const setCookie = (cname, cvalue, exdays) => {
    let c, d, e;

    if (exdays) {
        d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        e = ['expires=', d.toUTCString()].join('');
    }

    c = [cname, '=', cvalue, ';path=/'].join('');

    if (e) {
        c = [c, e].join(';');
    }

    document.cookie = c;
};

const getCookie = function getCookie(cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

// convert dotNet timezone ex./Date(1689726954583-0700)/ to UTC milliseconds"
const dotNetTimezoneToMillisecond = d => {
    const regexPattern = /\/Date\((\d+)([+-])(\d{2})(\d{2})\)\//;
    const match = d.match(regexPattern);

    if (match) {
        const timestamp = parseInt(match[1], 10);
        const offsetSign = match[2] === '-' ? -1 : 1;
        const offsetHours = parseInt(match[3], 10);
        const offsetMinutes = parseInt(match[4], 10);

        const offsetHoursMs = offsetHours * 3600000 * offsetSign;
        const offsetMinutesMs = offsetMinutes * 60000 * offsetSign;

        return timestamp + offsetHoursMs + offsetMinutesMs;
    }

    return null;
};

const getDateYMD = d => {
    const day = ['0', d.getDate()].join('');
    const m = ['0', d.getMonth() + 1].join('');
    return [d.getFullYear(), m.substring(m.length - 2), day.substring(day.length - 2)].join('-');
};

// date formats can range from 2023-09-25 to 2023-09-25T18:34:01.727
const daysAgo = (now, pastDate) => {
    if (typeof pastDate !== 'string') {
        return null;
    }

    if (typeof now !== 'string') {
        return null;
    }

    const c = pastDate.substr(0, 10);
    const d = new Date(c).getTime();
    const m = now.substr(0, 10);
    const n = new Date(m).getTime();

    const x = Math.floor((n - d) / (24 * 60 * 60 * 1000));

    if (typeof x === 'number' && !isNaN(x) && x >= 0) {
        return x;
    }

    return null;
};

/**
 * Extracts a YouTube URL from a string
 * @param {String} input string to be parsed
 * @return {String} YouTube URL or empty string if not found
 */
const getYouTubeUrl = (str) => {
    if (!str || typeof str !== 'string' || str.length < 1) return '';

    const youtubeUriRegex = /(?:youtube.com|youtu.be)\/[\w#\/;\?&=-]*/i;
    const match = str.match(youtubeUriRegex);

    if (match != null) {
        return match[0];
    }

    return '';
}
/**
 * Extracts the YouTube video key from a YouTube URL. The regex is not optimal
 * in that it will return the video key of the last YouTube URL from the input
 * string. So we run it through getYouTubeUrl to extract the first URL from the
 * input and proceed to grab the key.
 * @param {String} input string to be parsed
 * @return {String} video key or empty string if not found
 */
const getYouTubeVideoKey = (str) => {
    if (!str || typeof str !== 'string' || str.length < 1) return '';

    const youTubeUrl = getYouTubeUrl(str);
    const youtubeVideoKeyRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&amp;]v=|shorts\/)|youtu\.be\/)([^"&?\/ ]{11})/i;

    const match = youTubeUrl.match(youtubeVideoKeyRegex);
    if (match != null) {
        return match[1]; // return capture group 1
    }

    return '';
}

// Generate video iframe string based on a string input
const generateVideoPlayerIframe = (str, ll) => {
    const vidObj = {};
    const lazyload = ll || false;

    try {
        const youTubeKey = getYouTubeVideoKey(str);

        if (youTubeKey) {
            const htmlStr = `<iframe ${lazyload ? 'class="lazyload" ' : ''}width="100%" height="320px" controls=0 ${lazyload ? 'data-' : ''}src="https://www.youtube.com/embed/${youTubeKey}?theme=light&showinfo=0&hd=1&modestbranding=0&rel=0" frameborder="0" allowfullscreen ></iframe>`;
            vidObj.vidUrl = htmlStr;
            vidObj.reblastVideoClass = 'youtube-exists';
        } else if (str.match(/vimeo.com/gi)) {
            let vimeoRegex;

            if (str.match(/vimeo.com\/([0-9]+)\/([0-9A-Za-z]+)/gi)) {
                vimeoRegex = new RegExp('(?:vimeo\\.com)(?:.*)(?:/)([0-9]+)(?:/)');
            } else {
                vimeoRegex = new RegExp('(?:vimeo\\.com)(?:.*)(?:/)([0-9]+)');
            }

            const vimeoguid = str.match(vimeoRegex)[1];
            const vimeoStr = `<iframe ${lazyload ? 'class="lazyload" ' : ''}width="100%" height="320px" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ${lazyload ? 'data-' : ''}src="https://player.vimeo.com/video/${vimeoguid}"></iframe>`;

            vidObj.reblastVideoClass = 'vimeo-exists';
            vidObj.vidUrl = vimeoStr;
        } else if (str.match(/facebook.com/gi)) {
            let facebookStr;
            let facebookRegex;
            let facebookId;

            if (str.match(/videos/gi)) {
                facebookRegex = new RegExp('(https://www.facebook.com/)(\\S*)(videos/)([0-9]+)');
                facebookId = encodeURI(str.match(facebookRegex)[0]);
                facebookStr = `<iframe ${lazyload ? 'class="lazyload" ' : ''}${lazyload ? 'data-' : ''}src="https://www.facebook.com/plugins/video.php?href=${facebookId}%2F&show_text=0&width=560" width="100%" height="320" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>`;
            } else if (str.match(/watch/gi)) {
                facebookRegex = new RegExp('(?:v=)([0-9]+)');
                facebookId = str.match(facebookRegex)[1];
                facebookStr = `<iframe ${lazyload ? 'class="lazyload" ' : ''}${lazyload ? 'data-' : ''}src="https://www.facebook.com/video/embed?video_id=${facebookId}" width="100%" height="320" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>`;
            }

            vidObj.reblastVideoClass = 'facebook-exists';
            vidObj.vidUrl = facebookStr;
        } else if (str.match(/matterport.com/gi) && window.Modernizr.webgl) {
            const mpRegex = new RegExp(/(?:matterport\.com\S*[\?|#]m(odel)?=)([a-z|A-Z|0-9]+)/);
            const mpguid = mpRegex.exec(str)[2];
            const mpStr = `<iframe ${lazyload ? 'class="lazyload" ' : ''}width="100%" height="320px" ${lazyload ? 'data-' : ''}src="https://my.matterport.com/show/?m=${mpguid}" frameborder="0" allowfullscreen></iframe>`;
            
            vidObj.reblastVideoClass = 'mp-exists';
            vidObj.vidUrl = mpStr;
        } else if (str.match(/realsee.jp/gi) && window.Modernizr.webgl) {
            const realseeStr = `<iframe ${lazyload ? 'class="lazyload" ' : ''}width="100%" height="320px" ${lazyload ? 'data-' : ''}src="${str}" frameborder="0" allowfullscreen></iframe>`;
            vidObj.vidUrl = realseeStr;
        }
    } catch (e) {
        // swallow all errors
    }
    return vidObj;
}

// Generate Google StaticMap
const getGoogleStaticMap = (listings, settings = {}) => {
    if (!listings || typeof listings !== 'object') {
        return false;
    }

    const config = {
        format: settings.format || 'jpg',
        height: settings.height || '384',
        key: RESAAS.Environment.GoogleStaticMapsKey,
        markerIcon: settings.markerIcon || 'https://az291210.vo.msecnd.net/assets/7b6255fb-137d-4296-b37b-534219996a36.png',
        width: settings.width || '512',
        zoom: settings.zoom || '14',
    };

    listings = Array.isArray(listings) ? listings : [listings];

    if (
        listings.length === 1 &&
        (!listings[0].Address ||
            !listings[0].City ||
            !listings[0].State ||
            !listings[0].Country)
    ) {
        return listings[0].ListingType === 1
            ? 'https://az291210.vo.msecnd.net/assets/0694b2ca-57df-4867-8fe1-2816b8e5497e.png'
            : 'https://az291210.vo.msecnd.net/assets/282b2600-bd0a-49a9-8913-9a6ed18f9e41.png';
    }

    const buildMapViewQuery = (listings) => {
        if (listings.length === 1) {
            return `center=${encodeURIComponent(listings[0].Address)},${encodeURIComponent(listings[0].City)},${encodeURIComponent(listings[0].State)},${listings[0].Country}&zoom=${config.zoom}&`;
        }
        return '';
    };

    const buildMarkerList = (listings) => {
        return `markers=icon:${config.markerIcon}${listings
            .map(
                (listing) =>
                    `|${encodeURIComponent(listing.Address)},${encodeURIComponent(listing.City)},${encodeURIComponent(listing.State)},${listing.Country}`
            )
            .join('')}`;
    };

    const staticMapUrl = [
        'https://maps.googleapis.com/maps/api/staticmap?',
        buildMapViewQuery(listings),
        buildMarkerList(listings),
        `&size=${config.width}x${config.height}`,
        `&format=${config.format}`,
        `&key=${config.key}`,
    ].join('');

    return staticMapUrl;
};


const GeneralUtility = {
    addParamsToUrlQueryString,
    dotNetTimezoneToMillisecond,
    formatPhone,
    formatPhoneHref,
    formatNumber,
    isInViewport,
    linkifyTextString,
    objectToQueryString,
    queryStringToObject,
    queryStringToObjectPlus,
    addWindowLock,
    removeWindowLock,
    validateFields,
    riskyFiles,
    getLabelFromValue,
    getValueFromLabel,
    hasValue,
    appendPendingRequest,
    cleanupRequests,
    logWebRequestFailure,
    handleTableRowClick,
    renderCurrency,
    formatCurrency,
    setCookie,
    generateVideoPlayerIframe,
    getCookie,
    getDateYMD,
    getGoogleStaticMap,
    getYouTubeUrl,
    getYouTubeVideoKey,
    daysAgo
};

export default GeneralUtility;
