import axios from 'axios';
import _is from 'ramda/src/is';

const request = ({ method, url, data, headers, cancel }) => {
	const CancelToken = axios.CancelToken;
	let cancelFn;

	const requestObj = {
		responseType: 'json',
		method,
		url
	};

	if (cancel) {
		requestObj.cancelToken = new CancelToken(function executor(c) {
			// An executor function receives a cancel function as a parameter
			cancelFn = c;
		})
	}

	if (headers) {
		requestObj.headers = headers;
	}

	if (data && _is(Object, data)) {
		let isUrlEncoded, formData;

		if (headers && _is(Object, headers)) {
			isUrlEncoded = Object.keys(headers).find(
				key => key.match(/content-type/i) && headers[key].match(/x-www-form-urlencoded/i)
			);

			if (isUrlEncoded) {
				formData = new FormData();
				Object.keys(data).forEach(key => {
					formData.append(key, data[key]);
				});
			}
		}

		if (method.match(/get/i)) {
			requestObj.params = data;
		} else {
			requestObj.data = isUrlEncoded ? formData : data;
		}
	}

	if (cancel) {
		return {
			request: axios.request(requestObj),
			cancel: cancelFn
		};
	}

	return axios.request(requestObj);
};

export default request;
